import React, { useEffect, useRef, useState, useContext } from 'react';
import { toShortAddress, getCoinInfo, fontHelpers, toWei, GetHistoricalCoinDataWrapped } from '../functions/functions'

import QRCode from 'qrcode'
import QrReader from 'react-qr-reader'
import md5 from 'md5';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import moment from 'moment-timezone';

import {
  Box, Dialog, Slide, Typography, Button, FormControl, InputLabel, OutlinedInput, Grid,
  Divider, CircularProgress,
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { CopyAddressButton, QRCodeImg } from './Shared';

import { getCoinIcon, AddressScanner, SendAmount, WalletBox } from './Shared'
import { ReactComponent as BlueSuitcaseIcon } from '../icons/icon-suitcase-blue.svg';
import { ReactComponent as WarningIcon } from '../icons/icon-warning.svg';
import { ReactComponent as CloseIcon } from '../icons/icon-cross.svg';
import { ReactComponent as SwapToIcon } from '../icons/icon-swap-to.svg';
import { ReactComponent as ChartIcon } from '../icons/icon-chart.svg';
import { GenerateAuthenticatorKey, SetAuthenticator, GetTransactionLog, Withdraw, GetUser, UpdateFullName } from '../functions/network';
import { UserContext } from "../App";

import { Line, } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-moment';

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'
import theme from '../Themes'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);


const StyledFormControl = styled(FormControl)((theme) => ({
  width: "100%",
  marginTop: "32px",
  marginBottom: "24px",
}));

const StyledInputLabel = styled(InputLabel)((theme) => ({
  left: "-15px",
  top: "-14px",
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '16px',
  lineHeight: '20px',
  color: 'black',
  "&.Mui-focused": {
    color: "black",
  }
}));

const StyledOutlinedInput = styled(OutlinedInput)((theme) => ({
  borderRadius: "15px",
  backgroundColor: "white",
  border: "1px solid #000000",
  boxShadow: "inset 0px 2px 0px rgba(0, 0, 0, 0.8)",

  "&.Mui-disabled": {
    backgroundColor: "rgba(206, 210, 217, 0.7)",
  },
  ".MuiOutlinedInput-input": {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '13px',
    lineHeight: '16px',
  },
}));

const DialogPageHeaderStyles = {
  textAlign: "center", fontWeight: 700,
  fontSize: '16px',
  lineHeight: '20px',
};

const DialogBackButtonStyles = {
  position: "absolute",
  left: "16px", bottom: "14px",
  color: "black",
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '20px',
  textTransform: "None"
}

const DialogPageHeaderContainerStyles = {
  padding: "54px 24px 20px 24px",
  position: "relative", backgroundColor: "white",
}

const SlideInTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SlideUpTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const SlideInDialog = (props) => {
  const { language } = useLanguage();

  const backButtonText = !props.closeButtonText ? translations[language].close : props.closeButtonText;
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen
      TransitionComponent={SlideInTransition}
      PaperProps={{
        sx: {
          backgroundColor: "#F4F4F4",
        }
      }}
      TransitionProps={props.TransitionProps}
      scroll="paper"
    >
      <Box sx={DialogPageHeaderContainerStyles}>
        <Typography sx={DialogPageHeaderStyles}>{props.title}</Typography>
        <Button onClick={props.onClose} sx={DialogBackButtonStyles}>{backButtonText}</Button>
      </Box>
      <Box
        sx={{
          padding: "20px 20px 0 20px ",
        }}
      >
        {props.children}
      </Box>
    </Dialog>
  )
}

export const SecurityDialog = (props) => {
  const [verficationCode, setVerificationCode] = useState("");
  const [authKey, setAuthKey] = useState(null);
  const [QRCodeData, setQRCodeData] = useState(null);
  const [apiState, setApiState] = useState("init");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddAuthenticator = () => {
    if (!authKey.key) {
      setApiState("failure");
      setErrorMessage("We are still fetching the code from our server. Please wait a little bit longer.")
      return;
    }

    if (!verficationCode) {
      setApiState("failure");
      setErrorMessage("Please input the verification code from your authenticator.")
      return;
    }

    setApiState("loading");
    SetAuthenticator({
      authenticatorKey: authKey.key,
      authenticatorToken: verficationCode,
    })
      .then(() => {
        setApiState("success");
        setSuccessMessage("Authenticator added.")
      })
      .catch((err) => {
        setApiState("failure");
        setErrorMessage(err.data.message || err.data.error || "Sorry we are unable to process your request now. Please try again later.")
      });
  };

  const handleDialogOnEnter = () => {
    console.log("on enter. fetch the Key");
    if (!authKey) {
      GenerateAuthenticatorKey()
        .then(({ key, uri }) => {
          setAuthKey({
            key, uri
          });
          QRCode.toDataURL(uri, function (err, url) {
            if (err) {
              console.error("failed to convert to QR code");
              return;
            }

            setQRCodeData(url);
          })
        });
    }
  };

  const handleDialogOnExited = () => {
    console.log("onExited");
    // clean up
    setAuthKey(null);
    setQRCodeData(null);
    setApiState("init");
    setVerificationCode("");
  };

  return (
    <>
      <SlideInDialog
        open={props.open}
        onClose={props.onClose}
        title="Security"
        TransitionProps={{
          onEnter: handleDialogOnEnter,
          onExited: handleDialogOnExited,
        }}
      >
        <Box>
          <StyledFormControl>
            <StyledInputLabel shrink>Verification Code</StyledInputLabel>
            <StyledOutlinedInput
              name="verification-code"
              value={verficationCode}
              onChange={(e) => setVerificationCode(e.target.value.trim())}
              autoComplete='off' noValidate type="text"
            />
          </StyledFormControl>
        </Box>
        <WalletBox variant="contained" color="primary">
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography>Scan This On</Typography>
              <Typography>Google Authenticator</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: "20px" }}>
              {
                QRCodeData ?
                  <img src={QRCodeData} alt="qr-code" />
                  :
                  <>
                    <Typography sx={{ marginY: "20px" }}>Fetching the QR Code <CircularProgress /></Typography>

                  </>
              }

            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color="secondary" onClick={handleAddAuthenticator}
                sx={{
                  marginBottom: "20px",
                  width: "100%",
                }}
              >Add Authenticator</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Add extra security to your account - Two-factor authentication (2FA) protects your account by requiring an additional login code. Please scan the QRcode on this page with your authenticator, input verification code in the box above and press “Add Authenticator” to complete the set up.</Typography>
            </Grid>
          </Grid>
        </WalletBox>
      </SlideInDialog>
      <ApiProgressDialog
        open={apiState !== "init"}
        onClose={() => { setApiState("init") }}
        status={apiState}
        message={errorMessage}
        successMessage={successMessage}
      ></ApiProgressDialog>
    </>
  );
}

export const AccountDialog = (props) => {
  const [showNewOldPassword, setShowNewOldPassword] = useState(false);
  const [NewPassword, setNewPassword] = useState("");
  const [RePassword, setRePassword] = useState("");
  const [ReponseBoolen, setReponseBoolen] = useState(false);
  const [ReponseString, setReponseString] = useState('');
  const [Validate, setValidate] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [businessTitle, setBusinessTitle] = useState(null);
  const [businessCompany, setBusinessCompany] = useState(null);
  const [businessExpertise, setBusinessExpertise] = useState(null);
  const [businessLink, setBusinessLink] = useState(null);
  const [isEditingInfo, setIsEditingInfo] = useState(false);

  const { language, switchLanguage } = useLanguage();

  let passwordEndAdornment;

  useEffect(() => {
    setFirstName(props.userInfo.firstName)
    setLastName(props.userInfo.lastName)
    setMobileNumber(props.userInfo.mobileNumber)
    setBusinessTitle(props.userInfo.businessTitle)
    setBusinessCompany(props.userInfo.businessCompany)
    setBusinessExpertise(props.userInfo.businessExpertise)
    setBusinessLink(props.userInfo.businessLink)
  }, [props.userInfo.firstName, props.userInfo.lastName, props.userInfo.mobileNumber, props.userInfo.businessTitle, props.userInfo.businessCompany, props.userInfo.businessExpertise, props.userInfo.businessLink]);


  const VanishMessage = () => {
    setTimeout(() => {
      setReponseBoolen(false);
    }, 5000)
  }

  const handleSubmit = () => {
    if (showNewOldPassword) {
      if (NewPassword !== RePassword) {
        setReponseBoolen(true);
        setReponseString('Password Not Matches');
        VanishMessage();
        return;
      }
    } else if (isEditingInfo) {
      if (!firstName) {
        setReponseBoolen(true);
        setReponseString('Fill in firstname');
        VanishMessage();
        return;
      } else if (!lastName) {
        setReponseBoolen(true);
        setReponseString('Fill in last name');
        VanishMessage();
        return;
      } else if (!mobileNumber) {
        setReponseBoolen(true);
        setReponseString('Fill in mobile number');
        VanishMessage();
        return;
      }
    }

    const Auth = localStorage.getItem("AuthToken");

    if (showNewOldPassword) {
      const options = {
        mode: 'cors',
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          'x-auth-token': Auth,
        },
        body: JSON.stringify({
          "password": md5(NewPassword.trim().toLowerCase()),
        })
      };
      fetch(process.env.REACT_APP_BASE_URL + "/core/account/resetPassword", options)
        .then(response => response.json()).then(data => {
          if (data.code === 0) {
            //console.log(data.message);
            //console.log(data.results[0].token);
            setReponseBoolen(true);
            setReponseString('Password updated successfully');
          }
          else {
            setReponseBoolen(true);
            setReponseString('Error, Please contact admin@boredtech.xyz');
          }
          // console.log(data);
        }).catch(err => {
          console.log(err);
        })
    } else if (isEditingInfo) {
      const options = {
        mode: 'cors',
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          'x-auth-token': Auth,
        },
        body: JSON.stringify({
          firstName,
          lastName,
          mobileNumber,
          businessTitle,
          businessCompany,
          businessExpertise,
          businessLink,
        })
      };
      fetch(process.env.REACT_APP_BASE_URL + "/core/account/setUser", options)
        .then(response => response.json()).then(data => {
          if (data.code === 0) {
            //console.log(data.message);
            //console.log(data.results[0].token);
            setReponseBoolen(true);
            setReponseString('Updated successfully');
          }
          else {
            setReponseBoolen(true);
            setReponseString('Error, Please contact admin@boredtech.xyz');
          }
          // console.log(data);
        }).catch(err => {
          console.log(err);
        })
    }
  };

  return (
    <SlideInDialog
      open={props.open}
      onClose={props.onClose}
      title={translations[language].manageAccount}
    >

      <StyledFormControl>
        <Button
          onClick={() => switchLanguage(language === 'en' ? 'zh' : 'en')} variant="filled" size="small"
          sx={{
            position: "absolute",
            right: "0%",
            top: "-42px",
            '&:focus': {
              backgroundColor: theme.login.palette.primary.light,
              color: theme.login.palette.button.text,
            },
          }}
          color="primary"
        >
          {language === 'en' ? '中文' : 'English'}
        </Button>
      </StyledFormControl>
      <StyledFormControl>
        <StyledInputLabel shrink>{translations[language].email}</StyledInputLabel>
        <StyledOutlinedInput
          name="email"
          value={props.userInfo.email}
          autoComplete='off' noValidate type="text"
          disabled={true}
        />
      </StyledFormControl>

      {showNewOldPassword ?
        <>
          <StyledFormControl>
            <StyledInputLabel shrink>{translations[language].password}</StyledInputLabel>
            <StyledOutlinedInput
              name="password"
              placeholder={translations[language].enterPassword}
              onFocus={() => setValidate(false)}
              value={NewPassword}
              onChange={(e) => { setNewPassword(e.target.value); }}
              autoComplete='off' noValidate type="password"
            />
          </StyledFormControl>
          <StyledFormControl>
            <StyledInputLabel shrink>{translations[language].rePassword}</StyledInputLabel>
            <StyledOutlinedInput
              name="password"
              placeholder={translations[language].reEnterPassword}
              onFocus={() => setValidate(false)}
              value={RePassword}
              onChange={(e) => { setRePassword(e.target.value); }}
              autoComplete='off' noValidate type="password"
            />
          </StyledFormControl>
        </>
        :
        <>
          <StyledFormControl>
            <StyledInputLabel shrink>{translations[language].password}</StyledInputLabel>
            <StyledOutlinedInput
              name="password"
              placeholder='Enter your Password'
              onFocus={() => setValidate(false)}
              value="******"
              autoComplete='off' noValidate type="password"
              disabled
              onChange={(e) => setRePassword(e.target.value)}
              endAdornment={passwordEndAdornment}
            />
            <Button
              onClick={() => { setShowNewOldPassword(true) }} variant="filled" size="small"
              sx={{
                position: "absolute",
                right: "0%",
                top: "-42px",
              }}
              color="primary"
            >
              {translations[language].change + translations[language].password}
            </Button>
          </StyledFormControl>

          <StyledFormControl>
            <Button
              onClick={() => { setIsEditingInfo(true) }} variant="filled" size="small"
              sx={{
                position: "absolute",
                right: "0%",
                top: "0px",
              }}
              color="primary"
            >
              {translations[language].change}
            </Button>
          </StyledFormControl>

          <StyledFormControl>
            <StyledInputLabel shrink>{translations[language].firstName}</StyledInputLabel>
            <StyledOutlinedInput
              id="sign-up-firstName"
              name="firstName"
              onFocus={() => setValidate(false)}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              noValidate type="text"
              disabled={!isEditingInfo}
            />
          </StyledFormControl>

          <StyledFormControl>
            <StyledInputLabel shrink>{translations[language].lastName}</StyledInputLabel>
            <StyledOutlinedInput
              id="sign-up-lastName"
              name="lastName"
              onFocus={() => setValidate(false)}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              noValidate type="text"
              disabled={!isEditingInfo}
            />
          </StyledFormControl>

          <StyledFormControl>
            <StyledInputLabel shrink>{translations[language].mobileNumber}</StyledInputLabel>
            <StyledOutlinedInput
              id="sign-up-mobileNumber"
              name="mobileNumber"
              onFocus={() => setValidate(false)}
              value={mobileNumber}
              onChange={(e) => {
                // Allow only integers
                if (e.target.value === "" || /^[0-9]+$/.test(e.target.value)) {
                  setMobileNumber(e.target.value);
                }
              }}
              inputProps={{
                pattern: "[0-9]*",
                inputMode: "numeric"  // Improves the mobile keyboard experience
              }}
              noValidate type="text"
              disabled={!isEditingInfo}
            />
          </StyledFormControl>

          <StyledFormControl style={{ display: props.userInfo.accountType === 'Business' ? 'flex' : 'none' }}>
            <StyledInputLabel shrink>{translations[language].businessTitle}</StyledInputLabel>
            <StyledOutlinedInput
              id="sign-up-businessTitle"
              name="businessTitle"
              onFocus={() => setValidate(false)}
              value={businessTitle}
              onChange={(e) => setBusinessTitle(e.target.value)}
              noValidate type="text"
              disabled={!isEditingInfo}
            />
          </StyledFormControl>

          <StyledFormControl style={{ display: props.userInfo.accountType === 'Business' ? 'flex' : 'none' }}>
            <StyledInputLabel shrink>{translations[language].businessCompany}</StyledInputLabel>
            <StyledOutlinedInput
              id="sign-up-businessCompany"
              name="businessCompany"
              onFocus={() => setValidate(false)}
              value={businessCompany}
              onChange={(e) => setBusinessCompany(e.target.value)}
              noValidate type="text"
              disabled={!isEditingInfo}
            />
          </StyledFormControl>

          <StyledFormControl style={{ display: props.userInfo.accountType === 'Business' ? 'flex' : 'none' }}>
            <StyledInputLabel shrink>{translations[language].businessExpertise}</StyledInputLabel>
            <StyledOutlinedInput
              id="sign-up-businessExpertise"
              name="businessExpertise"
              onFocus={() => setValidate(false)}
              value={businessExpertise}
              onChange={(e) => setBusinessExpertise(e.target.value)}
              noValidate type="text"
              disabled={!isEditingInfo}
            />
          </StyledFormControl>

          <StyledFormControl style={{ display: props.userInfo.accountType === 'Business' ? 'flex' : 'none' }}>
            <StyledInputLabel shrink>{translations[language].businessLink}</StyledInputLabel>
            <StyledOutlinedInput
              id="sign-up-businessLink"
              name="businessLink"
              onFocus={() => setValidate(false)}
              value={businessLink}
              onChange={(e) => setBusinessLink(e.target.value)}
              noValidate type="text"
              disabled={!isEditingInfo}
            />
          </StyledFormControl>
        </>
      }


      {ReponseString === 'Updated successfully' ? <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Bold', letterSpacing: '1px', fontSize: '14px', color: 'green', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
        <CheckCircleOutlineIcon sx={{ mr: 1 }} />{ReponseString}
      </Typography> : <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
        <ErrorOutlineIcon sx={{ mr: 1 }} />{ReponseString}
      </Typography>}
      <Typography variant="h6" sx={Validate ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
        <ErrorOutlineIcon sx={{ mr: 1 }} />Fill all fields
      </Typography>

      <Button color="primary" onClick={handleSubmit} variant="contained"
        sx={{ width: "100%" }}
      >{translations[language].submit}</Button>
    </SlideInDialog>
  )
}

export const VerificationDialog = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [KYCHref, setKYCHref] = useState('');
  const [apiState, setApiState] = useState("init");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onExited = () => {
    setFirstName("");
    setLastName("");
    setKYCHref("");
    setApiState("init");
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleUpdateFullName = () => {
    if (!firstName) {
      setApiState("failure");
      setErrorMessage("Please input your first name");
      return;
    }

    if (!lastName) {
      setApiState("failure");
      setErrorMessage("Please input your last name");
      return;
    }

    setApiState("loading");
    UpdateFullName({
      firstName, lastName
    }).then(() => {
      return GetUser();
    }).then(user => {
      if (!user || !user.KYCHref) {
        throw Error("No KYCHref");
      }
      setApiState("success");
      setSuccessMessage("Close this pop up and press Start KYC button to continue the proceess.");
      setKYCHref(user.KYCHref);
    }).catch((err) => {
      setApiState("failure");
      setErrorMessage(err.data.message || err.data.error || "Sorry we are unable to process your request now. Please try again later.")
    });
  }

  const onClickStartKycBtn = () => {
    if (KYCHref)
      window.open(KYCHref, '_blank', 'height=600,width=560');
  }

  const onEnter = () => {
    GetUser().then(user => {
      if (user && user.firstName) {
        setFirstName(user.firstName)
      }
      if (user && user.lastName) {
        setLastName(user.lastName)
      }
      if (user && user.KYCHref) {
        setKYCHref(user.KYCHref)
      }
    })
  }

  return (
    <>
      <SlideInDialog
        open={props.open}
        onClose={props.onClose}
        title="Verification"
        TransitionProps={{
          onEnter,
          onExited,
        }}
      >
        <Box sx={{
          padding: "16px 24px", background: "#D9D9D9",
          boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
          borderRadius: "19px",
          ".MuiTypography-root": {
            "&:not(:last-child)": {
              marginBottom: "10px",
            }
          }
        }}
        >
          <Typography><WarningIcon /></Typography>
          <Typography variant="body2">Please input your First Name as well as Last Name and press “Update Full Name”. After successful update of full name, please press “Start verification” and complete subsequent procedures. You need to complete this for account withdrawal and other account functions</Typography>
          <Typography variant="body2">* Our service currently does not cover users from Singapore and U.S.</Typography>
        </Box>
        <StyledFormControl>
          <StyledInputLabel shrink>First Name</StyledInputLabel>
          <StyledOutlinedInput
            name="first-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete='off' noValidate type="text"
          />
        </StyledFormControl>

        <StyledFormControl>
          <StyledInputLabel shrink>Last Name</StyledInputLabel>
          <StyledOutlinedInput
            name="last-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete='off' noValidate type="text"
          />
        </StyledFormControl>

        <Button variant="contained" color="primary" onClick={handleUpdateFullName} sx={{
          marginBottom: "20px",
          width: "100%",
        }}
        >
          Update Full Name
        </Button>
        <Button variant="contained" startIcon={<BlueSuitcaseIcon />} color="secondary"
          sx={{
            width: "100%",
          }}
          onClick={onClickStartKycBtn}
          disabled={!KYCHref}
        >Start KYC</Button>
      </SlideInDialog>
      <ApiProgressDialog
        status={apiState}
        open={apiState !== "init"}
        onClose={() => setApiState("init")}
        message={errorMessage}
        successMessage={successMessage}
      />
    </>
  )
}

export const SlideUpDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      fullScreen={true}
      onClose={props.onClose}
      scroll={props.scroll}
      PaperProps={{
        sx: {
          height: "auto",
          borderRadius: "15px 15px 0px 0px",
          boxShadow: "0px -4px 0px rgba(0, 0, 0, 0.8)",
          backgroundColor: "white",
          ... (props.PaperSx || {})
        }
      }}
      TransitionComponent={SlideUpTransition}
      TransitionProps={props.TransitionProps}
      sx={{
        ".MuiDialog-container": {
          alignItems: "flex-end",
        },
        ...(props.sx ? props.sx : {})
      }}
    >
      <Box sx={{ padding: "24px", }}>
        <Typography sx={{
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '30px',
          marginBottom: "10px",
          ...(props.TitleSx ? props.TitleSx : {})
        }}>{props.title}</Typography>
        <Button onClick={props.onClose}
          sx={{
            position: "absolute",
            right: "0px",
            top: "16px",
            padding: "5px",
          }}
        >
          <CloseIcon />
        </Button>
        {props.children}
      </Box>

    </Dialog>
  )
}

export const TokenPickerDialog = (props) => {
  const { state } = useContext(UserContext);

  const selectedCoinType = props.selectedCoinType ? props.selectedCoinType : null;

  const handleCoinSelect = (e, asset) => {
    if (e.currentTarget.disabled || asset.coinType === selectedCoinType) {
      return;
    }
    props.onSelectCoin(asset.coinType);
  }

  const getAvailable = (coinType) => {
    if (coinType)
      return state[`${coinType.toLowerCase()}Available`];
    return 0
  }

  let items = props.assets.map((asset, i) => {
    return (
      <Grid item key={i} xs={12} sx={{ marginBottom: "10px" }}>
        <Button
          sx={{
            width: '100%',
            justifyContent: "space-between",
            padding: "10px 0",
            color: "black",
            ".MuiTypography-root": {
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "13px",
              lineHeight: "16px",
            },
          }}
          onClick={(e) => { handleCoinSelect(e, asset) }}
          disabled={asset.coinType === selectedCoinType}
        >
          <Box
            sx={{
              img: { display: "inline-block", verticalAlign: "middle", mr: "5px" },
              span: { verticalAlign: "middle" },
            }}
          >
            {getCoinIcon(asset.coinType)}
            <Typography component="span">{asset.coinName}</Typography>
          </Box>
          <Typography>{`${getAvailable(asset.coinType)} ${asset.coinUnit}`}</Typography>
        </Button>
      </Grid>
    )
  })
  return (
    <SlideUpDialog
      open={props.open}
      onClose={props.onClose}
      title="Token"
    >
      {/* TODO: add search box */}
      <Grid container sx={{ marginBottom: "30px" }}>
        {items}
      </Grid>
    </SlideUpDialog>
  );
}

export const SwapConfirmDialog = (props) => {
  const [step, setStep] = useState("review");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submitResult, setSubmitResult] = useState(null);

  if (!props.sourceTokenType || !props.destinationTokenType) {
    return null;
  }

  const shortAddress = toShortAddress(props.address);
  const srcIcon = getCoinIcon(props.sourceTokenType);
  const dstIcon = getCoinIcon(props.destinationTokenType);
  const srcCoinName = getCoinInfo(props.sourceTokenType).coinName;
  const dstCoinName = getCoinInfo(props.destinationTokenType).coinName;
  const srcAddr = "TODO: getSrcAddr";
  const dstAddr = "TODO: getDstAddr";
  const gasFee = "TODO: getGasFee";

  const DivierStyles = {
    borderColor: "white", margin: "10px -24px",
  };

  const cleanup = () => {
    setStep("review");
  };

  const handleSubmit = () => {
    setLoadingSubmit(true);
    // TODO call API;
    setTimeout(() => {
      setLoadingSubmit(false);
      setSubmitResult({
        reason: "",
        transaction: {
          id: "TODO: txid",
          ts: "TODO: ts",
        },
      })
      setStep("done");
    }, 3000);
  };

  const handleDone = () => {
    props.onClose();
    cleanup();
  };

  let title;
  switch (step) {
    case "review":
      title = "Swap"; break;
    case "confirm":
      title = "Enter Password"; break;
    case "done":
      title = "Result"; break;
    default:
      title = "Swap";
  }

  return (
    <SlideInDialog
      title={title}
      closeButtonText="Back"
      open={props.open}
      onClose={props.onClose}
    >
      <WalletBox variant="contained" color="primary" sx={{ pb: "28px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{props.name}</Typography>
            <Typography>{shortAddress}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" sx={DivierStyles} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
          <Box sx={{ lineHeight: "24px", "> *": { verticalAlign: "middle", } }}>
            {srcIcon}
            <Typography component="span" sx={{ ml: "5px" }}>{srcCoinName}</Typography>
          </Box>
          <Typography component="span">{props.sourceTokenAmount}</Typography>
        </Grid>
        <Grid item xs={12}>
          <WalletBox variant="filled" color="secondary">{srcAddr}</WalletBox>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "26px" }}>
          <SwapToIcon />
        </Grid>
        <Grid item xs={12}>
          <WalletBox variant="filled" color="secondary">{dstAddr}</WalletBox>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
          <Box sx={{ lineHeight: "24px", "> *": { verticalAlign: "middle", } }}>
            {dstIcon}
            <Typography component="span" sx={{ ml: "5px" }}>{dstCoinName}</Typography>
          </Box>
          <Typography component="span">{props.destinationTokenAmount}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>Gas fee</Typography>
          <Typography variant='body2'>{gasFee}</Typography>
        </Grid>

        {
          step === "review" ?
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" sx={{ width: "100%" }} onClick={() => setStep("confirm")}>Submit</Button>
            </Grid>
            : null
        }

        {
          step === "confirm" ?
            <>
              <Grid item xs={12}>
                <Divider variant="middle" sx={DivierStyles} />
              </Grid>
              <Grid item xs={12}>
                <StyledFormControl>
                  <StyledInputLabel shrink sx={{ color: "white" }}>Password</StyledInputLabel>
                  <StyledOutlinedInput
                    placeholder="Enter your Password"
                  />
                </StyledFormControl>
                <LoadingButton sx={{ width: "100%" }} variant="contained" color="secondary" onClick={handleSubmit} loading={loadingSubmit}>
                  Confirm
                </LoadingButton>
              </Grid>
            </>
            : null
        }

        {
          step === "done" ?
            <>
              <Grid item xs={12}>
                <Divider variant="middle" sx={DivierStyles} />
              </Grid>
              <Grid item xs={12} sx={{ mb: "20px" }}>
                <Typography variant='h3' sx={{ textAlign: "center" }}>{submitResult.reason ? "Failed" : "Success"}</Typography>
                {
                  submitResult.reason ?
                    <WalletBox variant="filled" color="secondary">
                      <Typography variant='h3'>{submitResult.reason}</Typography>
                    </WalletBox>
                    : null
                }
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mb: "6px" }}>
                  <Typography variant='body2'>Date</Typography>
                  <Typography variant='body2'>{submitResult.transaction.ts}</Typography>
                </Box>

                <Typography variant='body2' sx={{ mb: "6px" }}>Tx ID</Typography>

                <WalletBox variant="filled" color="secondary">
                  <Typography variant="body2">{submitResult.transaction.id}</Typography>
                </WalletBox>
              </Grid>
              <Grid item xs={12}>
                <Button sx={{ width: "100%" }} variant="contained" color="secondary" onClick={handleDone}>
                  Back To Wallet
                </Button>
              </Grid>
            </>
            : null
        }
      </WalletBox>
    </SlideInDialog>
  )
}

function PopUpDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      PaperProps={{
        sx: {
          mx: "0px",
          width: "calc(100% - 10px)",
          height: "340px",
          borderRadius: "15px",
          boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
          ...(props.PaperProps && props.PaperProps.sx ? props.PaperProps.sx : {})
        },
      }}
      sx={{
        ".MuiDialog-container": {
          maxWidth: "600px",
          mx: "auto",
        },
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Box
          sx={{
            height: "200px",
            display: "flex", justifyContent: "center", alignItems: "center",
            "> p": {
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "25px",
            },
          }}
        >
          {props.message}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
          {props.actions}
        </Box>
      </Box>
    </Dialog>
  )
}

export function LogOutDialog(props) {
  const { language } = useLanguage();

  return (
    <PopUpDialog
      open={props.open}
      onClose={props.onClose}
      message={<Typography>{translations[language].uSure}</Typography>}
      actions={
        <>
          <Button variant="contained" color="primary" onClick={props.onLogout} sx={{ width: "45%" }}>{translations[language].logout}</Button>
          <Button variant="contained" color="undesired" onClick={props.onClose} sx={{ width: "45%" }}>{translations[language].cancel}</Button>
        </>
      }
    >
    </PopUpDialog>
  )
}

export function ApiProgressDialog(props) {
  const { language, switchLanguage } = useLanguage();

  if (props.status === 'init') {
    return null;
  }

  let message = null;
  let actions = (
    props.status === 'success' || props.status === 'failure' ?
      (
        <Button sx={{ width: "45%" }} variant="contained" color="primary" onClick={props.onClose}>Back</Button>
      ) :
      null
  );
  switch (props.status) {
    case 'loading':
      message = (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <Typography><CircularProgress /></Typography>
          <Typography>{translations[language].apiLoading}</Typography>
        </Box>
      );
      break;
    case 'success':
      message = (
        props.successMessage ?
          props.successMessage :
          <Typography>{translations[language].apiSuccess}</Typography>
      );
      break;
    case 'failure':
      message = <Typography>{props.message}</Typography>
      break;
    default:
      console.error("unsuppored status %s", props.status);
  }



  return (
    <PopUpDialog
      open={props.open}
      onClose={props.onClose}
      message={message}
      actions={actions}
    />
  );
}

function DepositDialog(props) {
  return (
    <SlideUpDialog
      open={props.open}
      onClose={props.onClose}
      title="Deposit"
    >
      <WalletBox variant="contained" color="primary">
        <Box>
          <Typography sx={{ mb: "10px" }}>Your {props.coin.coinName} address</Typography>
          <Typography variant='body2'>You can send {props.coin.coinUnit} (ERC-20) token to this address using Ethereum network</Typography>
        </Box>
        <Divider variant="fullWidth" sx={{ borderColor: "white", my: "10px", mx: "-24px" }} />
        <Grid container sx={{ mb: "15px" }}>
          <Grid item xs={7}>
            <QRCodeImg data={props.address} style={{ width: "100%" }}></QRCodeImg>
          </Grid>
          <Grid item xs={5} sx={{ pl: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography sx={{ overflowWrap: "break-word", width: "100%" }}>{props.address}</Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: "15px" }}></Grid>

          <Grid item xs={7}>
            <Button variant="contained" color="secondary" sx={{ width: "100%", py: "12px" }}>Share</Button>
          </Grid>

          <Grid item xs={5} sx={{ pl: "15px" }}>
            <CopyAddressButton address={props.address} ButtonSx={{ width: "100%", py: "12px" }} />
          </Grid>
        </Grid>
      </WalletBox>
    </SlideUpDialog>
  )
}

function WithdrawDialog(props) {
  const [addr, setAddr] = useState("");
  // const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");

  const [apiState, setApiState] = useState("init");
  const [serverErrorMessage, setServerErrorMessage] = useState("");

  const { language, switchLanguage } = useLanguage();

  const onClose = (evt) => {
    setAddr("");
    setAmount("");
    setApiState("init");
    setServerErrorMessage("");
    props.onClose(evt);
  }

  const onApiFailure = () => {
    setApiState("init");
  }

  const handleAddressChange = (evt) => {
    setAddr(evt.target.value)
  }

  const handleAmountChange = (evt) => {
    setAmount(evt.target.value);
  }

  const handleConfirm = () => {
    if (!amount) {
      setApiState("failure")
      setServerErrorMessage("Please input the withdrawal amount.")
      return
    }

    if (!addr) {
      setApiState("failure")
      setServerErrorMessage("Please input the wallet address.")
      return
    }

    setApiState("loading");
    Withdraw({
      ccy: props.coin.coinType.toUpperCase(),
      network: "ERC20",
      amount: toWei(amount, props.coin.coinType.toUpperCase()),
      address: addr,
    }).then(() => {
      setApiState("success")
    }).catch((err) => {
      setApiState("failure")
      setServerErrorMessage(err.data.message || "Sorry we cannot process your request at this moment.")
    });
  }

  const apiProgressDialogProps = {
    open: apiState !== "init",
    status: apiState,
    onClose: () => {
      switch (apiState) {
        case 'success':
          return props.onConfirm();
        case 'failure':
          return onApiFailure();
        default:
          return onClose();
      }
    },
    message: serverErrorMessage,
    successMessage: (
      <Box>
        <img src='/assets/tick.png' alt="tick" style={{
          display: 'block',
          margin: '0 auto',
          width: '70px',
          marginBottom: '38px',
        }} />
        <Typography align='center'
          sx={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '25px',
          }}
        >Fund sent!</Typography>
      </Box>
    ),
  };

  return (
    <>
      <SlideUpDialog
        open={props.open}
        onClose={onClose}
      >
        <Typography sx={{ ...fontHelpers(700, 24, 30), mb: '5px' }}>Withdraw</Typography>
        <Typography variant="body2" sx={{ mb: '10px' }}>This withdrawal function refers to transferring an amount from wallet wallet to external wallet.</Typography>
        <WalletBox variant="contained" color="primary">
          <AddressScanner
            address={addr}
            onChange={handleAddressChange}
            onScan={(data) => { setAddr(data) }}
            title="Send to"
            inputPlaceHolder="Enter your ERC-20 Wallet Address"
          />

          <SendAmount
            available={props.coin.available}
            currency={props.coin.coinType}
            amount={amount}
            onChangeAmount={handleAmountChange}
            availableCurrencies={() => { return [props.coin] }}
          />

          <Button variant="contained" color="secondary"
            sx={{
              display: 'block',
              width: '126px',
              margin: '0 auto',
            }}
            onClick={handleConfirm}>Confirm</Button>
        </WalletBox>
      </SlideUpDialog>
      <ApiProgressDialog
        {...apiProgressDialogProps}
      />
    </>
  )
}

const TYPE_MAPPING = {
  "transfer": "wallet Pay"
};

function getDisplayType(txType) {
  const t = TYPE_MAPPING[txType.toLowerCase()];
  return t ? t : txType;
}

export function CoinTransactionHistoryDialog(props) {
  const [txRecords, setTxRecords] = useState(null);
  const [shouldOpenDepositDialog, setShouldOpenDepositDialog] = useState(false);
  const [shouldOpenWithdrawDialog, setShouldOpenWithdrawDialog] = useState(false);
  const { language, switchLanguage } = useLanguage();

  if (!props.coin) {
    return null;
  }

  const valueSx = { display: "flex", justifyContent: "space-between", mb: "5px" };

  const onEnter = async () => {
    try {
      let txRecords = await GetTransactionLog(props.coin.coinType);
      setTxRecords(txRecords);
    }
    catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <SlideUpDialog
        open={props.open}
        onClose={props.onClose}
        title={props.coin.coinName}
        TransitionProps={{
          onEnter
        }}
        scroll="body"
        PaperSx={{
          verticalAlign: 'bottom',
        }}
      >
        <Box sx={valueSx}>
          <Typography variant="body2">{translations[language].value}</Typography>
          <Typography variant="body2">{props.coin.value}&nbsp;{props.coin.coinUnit}</Typography>
        </Box>

        <Box sx={{ ...valueSx, ...{ color: "#aaa" } }}>
          <Typography variant="body2">{translations[language].available}</Typography>
          <Typography variant="body2">{props.coin.available}&nbsp;{props.coin.coinUnit}</Typography>
        </Box>

        <Box>
          <Typography sx={{ ...fontHelpers(700, 16, 20) }} align="center">{translations[language].transactionLog}</Typography>
          <TableContainer>
            {!txRecords &&
              <Typography variant="body2">Loading...</Typography>
            }
            {txRecords &&
              <Table size='small'>
                <TableHead>
                  <TableRow sx={{ "> .MuiTableCell-root": { padding: "0" } }}>
                    <TableCell>{translations[language].currency}</TableCell>
                    <TableCell>{translations[language].tranType}</TableCell>
                    {/* <TableCell></TableCell> */}
                    <TableCell>{translations[language].time}</TableCell>
                    <TableCell>{translations[language].amount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    'tr.tx-record-row:nth-of-type(odd)': {
                      backgroundColor: '#F7F8FA',
                    },
                  }}
                >
                  {txRecords.length === 0 &&
                    <TableRow>
                      <TableCell colSpan={4}>{translations[language].noTranFound}</TableCell>
                    </TableRow>
                  }
                  {txRecords.length > 0 && txRecords.map((tx, i) => (
                    <TableRow key={tx.txnHash || i} className="tx-record-row">
                      <TableCell>{tx.currency}</TableCell>
                      <TableCell>{getDisplayType(tx.type)} ({tx.creditOrDebit})</TableCell>
                      {/* <TableCell>{tx.creditOrDebit}</TableCell> */}
                      <TableCell>{moment.tz(tx.time, "Asia/Hong_Kong").format("YYYY-MMM-DD HH:mm")}</TableCell>
                      <TableCell align="right">{tx.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          </TableContainer>
        </Box>
      </SlideUpDialog>
      <DepositDialog
        coin={props.coin}
        open={shouldOpenDepositDialog}
        onClose={() => setShouldOpenDepositDialog(false)}
        address={props.address}
      />

      <WithdrawDialog
        open={shouldOpenWithdrawDialog}
        onClose={() => setShouldOpenWithdrawDialog(false)}
        coin={props.coin}
      />
    </>
  )
}

const FREQ_TO_INTERVAL_MAP = {
  daily: 1,
  year: 365,
};

function _getCoinName(coinType) {
  switch (coinType) {
    case 'bto':
      return 'bto';
    default:
      console.error('unknown coin %s', coinType);
      throw Error("Unknown coin");
  }
}

export function CoinChartDialog(props) {
  const { state, dispatch } = useContext(UserContext);
  const [freq, setFreq] = useState("daily");
  const [apiState, setApiState] = useState("success");

  const onClose = () => {
    setApiState("success");
    setFreq("daily");
    props.onClose();
  }

  const getHistoricalDataFromState = () => {
    const coin = _getCoinName(props.coin.coinType);
    const interval = FREQ_TO_INTERVAL_MAP[freq];

    return state.historicalData[coin][interval];
  }

  useEffect(() => {
    if (!props.coin || !props.coin.coinType) {
      return;
    }

    const coin = _getCoinName(props.coin.coinType);

    setApiState("loading")
    GetHistoricalCoinDataWrapped(
      coin, FREQ_TO_INTERVAL_MAP[freq], state, dispatch
    ).then(() => {
      setApiState("success");
    }).catch((err) => {
      setApiState("failure");
    });

  }, [freq, props.coin, props.coin.coinType, state, dispatch])

  if (!props.coin || Object.keys(props.coin).length === 0) return null;

  let icon = React.cloneElement(
    getCoinIcon(props.coin.coinType),
    { style: { marginRight: '5px', } }
  );

  let title = (
    <>
      <span style={{ marginRight: '5px', }}>{props.coin.coinName} Movement in Period</span>
      {icon}
      <ChartIcon />
    </>
  )

  return (
    <>
      <SlideUpDialog
        open={props.open}
        onClose={onClose}
        title={title}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F7F8FA',
          padding: '2px',
          mb: "10px",

          ".MuiButton-root": {
            flex: 1,
            color: 'black',
            borderRadius: '7px',
            padding: '6px 8px',

            '&.MuiButton-contained': {
              backgroundColor: 'white',
              border: '0.5px solid rgba(0, 0, 0, 0.04)',
              boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
              fontWeight: 'bold',
            }
          }
        }}
        >
          <Button
            variant={freq === "daily" ? "contained" : "text"}
            onClick={() => setFreq("daily")}
          >
            24 Hours
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem />

          <Button variant={freq === "year" ? "contained" : "text"}
            onClick={() => setFreq("year")}
          >365 Days</Button>
        </Box>

        <Box sx={{ minHeight: "400px" }}>
          {apiState === "success" &&
            <LineChart
              prices={getHistoricalDataFromState().prices}
              freq={freq}
            />
          }
        </Box>
      </SlideUpDialog>
    </>
  );
}

export function QRCodeScanner(props) {
  const title = props.title || "Scan QR Code";
  const message = props.message || "Scan address codes to send funds";
  // The combination of useState and useRef is necessary. useRef + useEffect alone failed to capture all the events.
  const [refVisible, setRefVisible] = useState(false);
  const readerRef = useRef(null);

  const handleError = (err) => {
    props.onError(err);
  };

  const handleScan = (data) => {
    if (!data) {
      // No QR code found.
      return
    }

    props.onData(data);
  };

  useEffect(() => {
    if (!refVisible) {
      return
    }

    // WARNING: this is very hacky, fragile.
    const videoNode = readerRef.current.els.preview;
    // Manually inject the view finder as it is impossible to customize the finder + CSS cant create the view finder effect.
    const imgNode = document.createElement('img');
    imgNode.src = '/assets/wallet_view_finder.png';
    imgNode.style.position = 'absolute';
    imgNode.style['z-index'] = '100';
    imgNode.style.width = '100%';
    imgNode.style.top = '0';
    imgNode.style.left = '0';
    videoNode.parentNode.insertBefore(imgNode, videoNode);
  }, [refVisible]);

  return (
    <Dialog
      className='QRCodeScanner'
      open={props.open}
      fullScreen={true}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          backgroundColor: '#666666',
        }
      }}
      TransitionComponent={SlideUpTransition}
      TransitionProps={props.TransitionProps}
      sx={{
        ".MuiDialog-container": {
          alignItems: "flex-end",
        },
        ...(props.sx ? props.sx : {})
      }}
    >
      <Box sx={{ padding: "24px", }}>
        <Typography sx={{
          color: 'white',
          fontStyle: 'normal',
          ...fontHelpers(700, 24, 30),
          marginBottom: "10px",
          ...(props.TitleSx ? props.TitleSx : {})
        }}>{title}</Typography>
        <Button onClick={props.onClose}
          sx={{
            position: "absolute",
            right: "0px",
            top: "16px",
            padding: "5px",
          }}
        >
          <CloseIcon sx={{ color: 'transparent' }} />
        </Button>
      </Box>

      <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', height: '375px', }}>
        <QrReader
          ref={el => { readerRef.current = el; setRefVisible(!!el); }}
          // ref={readerRef}
          facingMode={"environment"}
          delay={500}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%" }}
          showViewFinder={false}
        />
      </Box>


      <Box sx={{ padding: "24px", }}>
        <Typography
          align="center"
          sx={{
            color: 'white',
            fontStyle: 'normal',
            ...fontHelpers(700, 16, 20),
            marginBottom: "10px",
            ...(props.MessageSx ? props.MessageSx : {})
          }}>{message}</Typography>
      </Box>
    </Dialog>
  )
}

const LineChart = (props) => {
  if (!props.prices) return null;

  let labels = props.prices.map(([ts, _]) => {
    let date = new Date(ts);
    return date;
  })

  let datasets = [
    {
      label: 'Price',
      data: props.prices.map(([ts, price]) => { return { x: ts, y: price } }),
      borderColor: '#4318FF',
      backgroundColor: 'white',
      pointStyle: 'circle',
      pointRadius: 0,
      pointHoverRadius: 6,
      borderWidth: 1
    },
  ]
  let data = {
    labels, datasets,
  }

  let options = {
    maintainAspectRatio: false,
    scales: {
      yAxes:
      {
        ticks: {
          beginAtZero: true,
          color: 'black',
        },
      },
      x: {
        type: "time",
        max: (new Date()).getTime(),
        ticks: {
          color: 'black',
          display: true
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
  }

  return (
    <Line
      data={data}
      options={options}
      height="400px"
    />
  )
}