import { createTheme } from '@mui/material/styles';

const globalTheme = {
  palette: {
    undesired: {
      main: "#E0E0E0",
      contrastText: "#2d2d2d",
    },
    primary: {
      main: "#2A1662",
    },
    button: {
      text: "white"
    }
  },
  typography: {
    fontFamily: [
      '"Readex Pro"',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.variant === 'body2' && {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "11px",
            lineHeight: "14px",
          }),
          ...(ownerState.variant === 'h3' && {
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "30px",
          }),
        }),
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => ({
          borderRadius: "15px",
          textTransform: "none",
          ...(ownerState.variant === 'contained' && {
            boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
          }),

          ...(
            (ownerState.variant === 'contained' || ownerState.variant === 'filled') &&
            ownerState.color === 'primary' && {
              backgroundColor: '#2A1662',
              color: '#fff',
          }),

          ...(
            (ownerState.variant === 'contained' || ownerState.variant === 'filled') &&
            ownerState.color === 'secondary' && {
              backgroundColor: 'white',
              color: "#004AAD",
              "&:hover": {
                background: "white",
                boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
              }
          }),

          ...(
            ownerState.size === "medium" && {
              paddingTop: '18px',
              paddingBottom: '14px',
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              fontStyle: 'normal',
            }
          ),
        }),
      }
    },
    MuiBox: {
      styleOverrides: {
        root: ({ownerState}) => ({
          "&.wallet-app-page": {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            pb: "150px",
            maxWidth: '600px',
            margin: 'auto',
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ownerState}) => ({
          maxWidth: '600px',
          marginLeft: "auto",
          marginRight: "auto",
        })
      }
    }
  }
};

const themes = {
  global: createTheme(globalTheme),
  wallet: createTheme({
    ...globalTheme,
  }),
  // It is a theme shared by both Login and SignUp page.
  login: createTheme({
    ...globalTheme,
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: "100%",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            color: "#000",
            transform: "none",  /* override MUI */
          },
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            color: "#000",
            transform: "none",  /* override MUI */
          },
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            marginTop: '24px',
            borderRadius: '15px',
            boxShadow: "inset 0px 2px 0px rgba(0, 0, 0, 0.8)",
            padding: '8px 18px',
            fontSize: '16px',
            border: '1px solid black',
          },
          input: {
            padding: '0',
            '&::placeholder': {
              color: '#777',
              opacity: '1',
              fontSize: '13px',
              fontWeight: '300',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            marginTop: 4,
            width: '100%',
            height: '50px',
            fontSize: '16px',
            borderRadius: '15px',
            background: '#244CA6;',
            textTransform: "none",  // override MUI
            fontWeight: "700",
            display: "block", textAlign: "left",
            boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
          }
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: ({ownerState}) => ({
            ...(ownerState.variant === 'h1' && {
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "30px",
                width: "calc(100% - 50px)",
            }),
          }),
        }
      }
    },
  }),
};

export default themes;