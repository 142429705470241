import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { UserContext } from "../App"
import { useHistory } from 'react-router-dom';
import {
    ShoppingCart,
    ModeEdit,
    Add,
    Delete
} from '@mui/icons-material';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
    Modal,
    Box,
    Button,
    Snackbar,
    TextField,
    Alert,
    IconButton
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import theme from '../Themes'

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

import { PageHeader } from './Shared.jsx';
import { isAuth } from '../functions/functions.js'

function StoreCMS() {
    const history = useHistory();

    const { dispatch } = useContext(UserContext);
    const { language, switchLanguage } = useLanguage();
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    // State for controlling the visibility of the modal
    const [openModal, setOpenModal] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [images, setImages] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [openDisclaimerDialog, setOpenDisclaimerDialog] = useState(false)
    const [initialSelectedProduct, setInitialSelectedProduct] = useState(null);
    const ITEMS_PER_PAGE = 6; // Set the number of items you want per page
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [end, setIsEnd] = useState(false);
    const loader = useRef(null);

    const handleObserver = useCallback(
        (entries) => {
            const target = entries[0];
            if (target.isIntersecting && !loading) {
                fetchProducts(page);
            }
        },
        [loading, page] // Ensure the effect depends on the loading and page state
    );

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });

        if (loader.current) observer.observe(loader.current);

        return () => observer.disconnect(); // Clean up the observer properly
    }, [handleObserver]);

    const fetchProducts = useCallback((pageNum) => {
        if (loading || end) return; // Exit early if already loading

        setLoading(true);
        try {
            isAuth(history).then(async (user) => {
                if (user) {
                    const response = await fetch(
                        `${process.env.REACT_APP_BASE_URL}/core/store/list?page=${pageNum}&limit=${ITEMS_PER_PAGE}`
                    );
                    const data = await response.json();
                    if (data.products.length) {
                        const newProducts = data.products.filter((product) => { return product.owner === user.email || user.accountType === 'Admin' })
                        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
                    } else if (data.products.length < 6) {
                        // If no data is returned, we can assume there are no more pages to fetch.
                        // You could set a state here to indicate that you've reached the end.
                        setIsEnd(true)
                    }
                    setLoading(false);
                    setPage((ref) => ref + 1)
                }
            }).catch((e) => {
                window.localStorage.removeItem("AuthToken");
                window.localStorage.removeItem("DisplayCurrency");
                history.push('/');
            })
        } catch (err) {
            console.error('Error fetching products:', err);
        } finally {
        }
    }, [loading]);


    const handleCloseModal = () => {
        setOpenModal(false)
        setSelectedProduct(null);
    };

    const handleOpenModal = async (product) => {
        if (product) {
            setSelectedProduct(product);
            setInitialSelectedProduct(product); // Set the initial state here
        } else {
            setSelectedProduct(null);
        }

        setOpenModal(true); // This should open the modal
    };

    function compressImage(file, maxWidth, maxHeight, quality) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                const compressed = canvas.toDataURL(file.type, quality);
                URL.revokeObjectURL(img.src); // Revoke the object URL now that we're done with it
                resolve(compressed);
            };
            img.onerror = error => {
                URL.revokeObjectURL(img.src);
                console.error('There was an error processing the image', error);
                reject(error);
            };
        });
    }

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files.length > 6) {
            alert('You can upload a maximum of 6 images.');
            event.target.value = '';
            return
        }
        if (event.target.files) {
            const filesArray = Array.from(event.target.files).map((file, index) => {
                //const MAX_SIZE = 10 * 1024 * 1024; // 10MB
                if (!file.type.startsWith('image/')) {
                    alert('Only images are allowed.');
                    return null;
                }
                //if (file.size > MAX_SIZE) {
                //    alert('Image file size is too large. Maximum size is 1MB.');
                //    return null;
                //}

                return compressImage(file, 260, 260, 0.7)
                    .then(compressedBase64 => {
                        return { base64: compressedBase64, order: index + 1 };
                    })
                    .catch(error => {
                        console.error('Error during image compression:', error);
                        return null;
                    });
            }).filter(file => file != null);

            Promise.all(filesArray)
                .then(images => {
                    console.log(images)
                    if (images.length > 6) {
                        alert('You can upload a maximum of 6 images.');
                    } else {
                        setImages(images); // This assumes setImages is your state setter for images
                    }
                })
                .catch(error => {
                    console.error('Error reading file:', error);
                });
        }
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((value, key) => value && value[key] ? value[key] : null, obj);
    };

    const validateFields = () => {
        const fields = ['name.en', 'name.zh', 'description.en', 'description.zh', 'price', 'originalPrice', 'quantity'];
        for (const field of fields) {
            if (!getNestedValue(selectedProduct, field)) {
                // If any field is empty or not provided, return false
                return false;
            }
        }
        // If all fields are valid, return true
        return true;
    };

    const ImagePreview = ({ images }) => {
        // Handle drag and drop reordering logic here
        return (
            <div>
                {images.map((image, index) => (
                    <div key={index} /* draggable, onDragStart, etc. */>
                        <img src={image.base64} width="100" height="100" />
                        {/* Add drag handle */}
                    </div>
                ))}
            </div>
        );
    };

    const handleUpdate = async () => {
        try {
            // Sending a POST request with the updated product data
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/core/store/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem("AuthToken"),
                },
                body: JSON.stringify({
                    ...selectedProduct,
                    images
                }),
            });

            // Handle response
            const data = await response.json();
            if (data.code === 0) {
                // Update successful
                setSnackbarMessage('Update success')
                setSnackbarSeverity("info");
                console.log('Update success:', data);
                setOpenSnackbar(true);
                // ... any other state updates based on successful update
            } else {
                // Handle errors, if any
                console.error('Update failed:', data);
                //add OpenSnackbar message error "please contact admin"
                setSnackbarMessage("Update failed, please contact admin");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
        } catch (error) {
            // Handle any other errors
            console.error('Update error:', error);
            //add OpenSnackbar message error "please contact admin"
            setSnackbarMessage("Update failed, please contact admin");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setOpenDisclaimerDialog(false)
            handleCloseModal(); // This will close the modal whether the update is successful or not
            setTimeout(() => {
                setOpenSnackbar(false); // Hide the Snackbar after a delay
            }, 3000); // Adjust the timeout as needed
        }
    };

    const handleDelete = async () => {
        const productId = selectedProduct.id
        console.log(productId)
        // Call the backend API to delete the product
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/core/store/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem("AuthToken"),
                },
                body: JSON.stringify({ id: productId }),
            });

            const data = await response.json();
            if (data.code === 0) {
                // If successful, remove the product from the state
                setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
                setSnackbarMessage('Product deleted successfully');
                setSnackbarSeverity("success");
            } else {
                setSnackbarMessage(data.message || 'Error deleting product');
                setSnackbarSeverity("error");
            }
        } catch (error) {
            setSnackbarMessage('Error deleting product');
            setSnackbarSeverity("error");
        } finally {
            setOpenSnackbar(true);            
            handleCloseModal(); // This will close the modal whether the update is successful or not
            setTimeout(() => {
                setOpenSnackbar(false); // Hide the Snackbar after a delay
            }, 3000); // Adjust the timeout as needed
        }
    };


    const handleUpdateClick = () => {
        if (validateFields()) {
            setOpenDisclaimerDialog(true);
        } else {
            setSnackbarMessage('All fields are required and cannot be blank.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    return (
        <ThemeProvider theme={theme.wallet}>
            <Box className="wallet-app-page"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',

                    maxWidth: '600px', margin: 'auto',

                    backgroundColor: "#F4F4F4",
                    width: "100%",
                    minHeight: "100vh",
                    paddingTop: "72px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingBottom: "150px",
                }}
            >
                <PageHeader auth={true} title={translations[language].storeCMS} showLogout={true} sx={{ width: "100%" }} />
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        mb: "68px",
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {products.map((product) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={product.id} sx={{ minWidth: 284 }}>
                                    <Card sx={{ height: 350 }}>
                                        <CardActionArea onClick={() => handleOpenModal(product)}>
                                            <CardMedia
                                                component="img"
                                                height="268"
                                                width="268"
                                                image={product?.images && product.images[0]?.base64}
                                                alt={product.name[language]}
                                            />
                                            <CardContent>
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Box>
                                                        <Typography gutterBottom variant="h8" component="div">
                                                            {product.name[language]}
                                                        </Typography>
                                                        {product.originalPrice > 0 ? (
                                                            // Show the original price crossed out and the discounted price
                                                            <>
                                                                <Typography variant="body2" style={{ textDecoration: 'line-through' }}>
                                                                    {product.originalPrice} {product.currency.toUpperCase()}
                                                                </Typography>
                                                                <Typography variant="body1" color="primary">
                                                                    {product.price} {product.currency.toUpperCase()}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            // If there is no discount, just show the price
                                                            <Typography variant="body1">
                                                                {product.price} {product.currency.toUpperCase()}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    <Box>
                                                        <ModeEdit style={{ fontSize: '3rem' }} color={'primary'} />
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                            <div>
                                {/* Render products */}
                                <div ref={loader} />
                            </div>
                            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ minWidth: 284 }}>
                                <Card sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 350
                                }}>
                                    <CardActionArea sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} onClick={() => handleOpenModal(null)}>
                                        <Add style={{ fontSize: '10rem' }} color={'primary'} />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={3000}
                            onClose={() => setOpenSnackbar(false)}
                            message={snackbarMessage}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                        <Modal
                            open={openModal}
                            onClose={() => setOpenModal(false)}
                            aria-labelledby="product-detail-title"
                            aria-describedby="product-detail-description"
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                maxHeight: '90vh', // Set a maximum height
                                overflowY: 'auto', // Add a scrollbar
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                outline: 'none',
                            }}
                            >
                                <Typography id="product-detail-title" variant="h6" component="h2">
                                    {selectedProduct ? translations[language].editProduct : translations[language].addProduct}
                                </Typography>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        required
                                        id="name-en"
                                        label="Name (EN)"
                                        error={!getNestedValue(selectedProduct, 'name.en')}
                                        defaultValue={selectedProduct?.name?.en || ''}
                                        onChange={(e) => {
                                            setSelectedProduct((prevProduct) => ({
                                                ...prevProduct,
                                                name: {
                                                    ...prevProduct?.name,
                                                    en: e.target.value,
                                                },
                                            }));
                                        }}
                                    />
                                    <TextField
                                        required
                                        id="name-zh"
                                        label="Name (ZH)"
                                        error={!getNestedValue(selectedProduct, 'name.zh')}
                                        defaultValue={selectedProduct?.name?.zh || ''}
                                        onChange={(e) => {
                                            setSelectedProduct((prevProduct) => ({
                                                ...prevProduct,
                                                name: {
                                                    ...prevProduct?.name,
                                                    zh: e.target.value,
                                                },
                                            }));
                                        }}
                                        multiline
                                    />

                                    <TextField
                                        required
                                        id="description-en"
                                        label="Description (EN)"
                                        error={!getNestedValue(selectedProduct, 'description.en')}
                                        defaultValue={selectedProduct?.description?.en || ''}
                                        onChange={(e) => {
                                            setSelectedProduct((prevProduct) => ({
                                                ...prevProduct,
                                                description: {
                                                    ...prevProduct?.description,
                                                    en: e.target.value,
                                                },
                                            }));
                                        }}
                                        multiline
                                    />

                                    <TextField
                                        required
                                        id="description-zh"
                                        label="Description (ZH)"
                                        error={!getNestedValue(selectedProduct, 'description.zh')}
                                        defaultValue={selectedProduct?.description?.zh || ''}
                                        onChange={(e) => {
                                            setSelectedProduct((prevProduct) => ({
                                                ...prevProduct,
                                                description: {
                                                    ...prevProduct?.description,
                                                    zh: e.target.value,
                                                },
                                            }));
                                        }}
                                        multiline
                                    />

                                    <TextField
                                        required
                                        id="price"
                                        label="Price"
                                        type="number"
                                        error={!getNestedValue(selectedProduct, 'price')}
                                        defaultValue={selectedProduct?.price || ''}
                                        onChange={(e) => {
                                            setSelectedProduct((prevProduct) => ({
                                                ...prevProduct,
                                                price: e.target.value,
                                            }));
                                        }}
                                    />

                                    <TextField
                                        required
                                        id="originalPrice"
                                        label="Original Price"
                                        type="number"
                                        error={!getNestedValue(selectedProduct, 'originalPrice')}
                                        defaultValue={selectedProduct?.originalPrice || ''}
                                        onChange={(e) => {
                                            setSelectedProduct((prevProduct) => ({
                                                ...prevProduct,
                                                originalPrice: e.target.value,
                                            }));
                                        }}
                                    />

                                    <TextField
                                        required
                                        id="quantity"
                                        label="Quantity (Enter -1 for infinity)"
                                        type="number"
                                        error={!getNestedValue(selectedProduct, 'quantity')}
                                        defaultValue={selectedProduct?.quantity || ''}
                                        onChange={(e) => {
                                            setSelectedProduct((prevProduct) => ({
                                                ...prevProduct,
                                                quantity: e.target.value,
                                            }));
                                        }}
                                    />

                                    <Box>
                                        <input
                                            accept="image/*"
                                            type="file"
                                            multiple
                                            onChange={handleImageChange}
                                        />
                                        <ImagePreview images={images} />
                                        {images.length === 0 && (
                                            <Typography variant="body2" color="textSecondary">
                                                No images selected. A default image will be displayed.
                                            </Typography>
                                        )}
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            onClick={handleUpdateClick}
                                            disabled={JSON.stringify(selectedProduct) === JSON.stringify(initialSelectedProduct)}
                                        >
                                            Update
                                        </Button>
                                        <IconButton color="error" onClick={handleDelete}>
                                            <Delete style={{ fontSize: '3rem' }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>
                        <Dialog
                            open={openDisclaimerDialog}
                            onClose={() => setOpenDisclaimerDialog(false)}
                            aria-labelledby="disclaimer-dialog-title"
                            aria-describedby="disclaimer-dialog-description"
                        >
                            <DialogContent>
                                <DialogContentText id="disclaimer-dialog-description">
                                    By using this platform to update and sell products, you agree to ensure the accuracy, legality, and regulatory compliance of your listings. You are solely responsible for the items you sell and the content you post. You indemnify Bored Technology Limited against all claims arising from your use of this service. Ensure adherence to all laws and regulations related to commerce, intellectual property, and consumer protection. Confirming below signifies your understanding and agreement to these terms.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDisclaimerDialog(false)}>Cancel</Button>
                                <Button onClick={handleUpdate} autoFocus>
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Box>
            </Box >
        </ThemeProvider >
    )
}

export default StoreCMS;
