import React, { useContext, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material"
import './Navbar.css';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { UserContext } from "../App"
import { SlideUpDialog, ApiProgressDialog } from './Dialogs';
import { WhiteDivider, QRCodeImg, CopyAddressButton, AddressScanner, SendAmount, WalletBox } from './Shared';
import { fontHelpers, toWei, GetWalletAssetsWrapped } from '../functions/functions'
import { Pay } from '../functions/network';
import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

function NavbarAction(props) {
  const location = useLocation();

  const isActive = location.pathname.startsWith(props.url);

  const linkClassName = isActive ? "nav-link active" : "nav-link";

  const img = (
    isActive ?
      <img className="icon" src={props.img.src.active} alt={props.img.alt} /> :
      <img className="icon" src={props.img.src.normal} alt={props.img.alt} />
  );
  
  return (
    props.disabled ? (
      <Box className={`icon-container ${linkClassName}`}>
        {img}
        <Typography variant="h6" component="div" className='text'>
          {props.text}
        </Typography>
      </Box>
    ) : (
      <Link to={props.url} className={linkClassName}>
        <Box className="icon-container">
          {img}
        </Box>
        <Typography variant="h6" component="div" className='text'>
          {props.text}
        </Typography>
      </Link>
    )
  );
  
}

function PayButton(props) {
  const { language, switchLanguage } = useLanguage();

  const boxWidth = 10;
  const btnWidth = 70;
  const btnMarginLeft = -(btnWidth / 2) + (boxWidth / 2);

  return (
    <Box sx={{
      width: `${boxWidth}px`,
    }}>
      <Button
        sx={{
          flexDirection: 'column',
          background: 'white',
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25), 0px 4px 0px rgba(0, 0, 0, 0.8)",
          border: '0',
          borderRadius: '50px',
          width: `${btnWidth}px`,
          height: `${btnWidth}px`,
          marginLeft: `${btnMarginLeft}px`,
          marginTop: '-75px',

          '&:hover': {
            background: 'white',
          },

          '.text': {
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '16px',
            color: 'black',
          },
        }}
        onClick={props.onClick}
      >
        <img src="/assets/logo_small.svg" alt="wallet logo" />
        <Typography className='text'>{translations[language].pay}</Typography>
      </Button>
    </Box>
  )
}

function Navbar() {
  const { state, dispatch } = useContext(UserContext);
  const [showPay, setShowPay] = useState(false);
  const { language, switchLanguage } = useLanguage();

  const handlePay = (evt) => {
    setShowPay(true);
  };

  const actions = [
    {
      url: "/wallet",
      text: translations[language].wallet,
      img: {
        src: {
          normal: "/assets/navbar-icon-wallet.svg",
          active: "/assets/navbar-icon-wallet-active.svg",
        },
        alt: translations[language].wallet,
      },
    },
    //{
    //  url: "/product",
    //  text: "Product",
    //  img: {
    //    src: {
    //      normal: "/assets/navbar-icon-product.svg",
    //      active: "/assets/navbar-icon-product-active.svg",
    //    },
    //    alt: "Product",
    //  },
    //},
    {
      //url: "/news",
      text: "News",
      img: {
        src: {
          normal: "/assets/navbar-icon-markets.svg",
          active: "/assets/navbar-icon-markets-active.svg",
        },
        alt: "News",
      },
      disabled: true
    },
    {
      url: '#pay',
    },
    {
      url: "/store",
      text: "Store",
      img: {
        src: {
          normal: "/assets/navbar-icon-product.svg",
          active: "/assets/navbar-icon-product-active.svg",
        },
        alt: "Store",
      },
    },
    {
      url: "/aboutus",
      text: translations[language].about,
      img: {
        src: {
          normal: "/assets/navbar-icon-aboutus.svg",
          active: "/assets/navbar-icon-aboutus-active.svg",
        },
        alt: translations[language].about,
      },
    },
  ].map((action, i) => {
    if (action.url === '#pay') {
      return <PayButton key={action.url} onClick={handlePay} />
    }
    return <NavbarAction key={action.url} {...action} />
  })


  return (
    <>
      <Box component={'div'}
        className="navbar"
        sx={
          {
            pointerEvents: state.loggedIn ? 'auto' : 'none',
            display: state.showNavbar ? { md: 'flex', xs: 'flex' } : "none",
          }
        }
      >
        {actions}
      </Box>
      <PayButtonDialog
        open={showPay}
        onClose={(isPaidSuccess) => {
          setShowPay(false);
          if (isPaidSuccess) {
            GetWalletAssetsWrapped(dispatch)
          }
        }}
      />
    </>
  )
}

function SwitchButton(props) {
  return (
    <Button sx={{
      flex: 1,
      borderRadius: '7px',
      border: '0.5px solid transparent',
      color: 'rgba(0, 0, 0, 0.5)',
      ...fontHelpers(500, 13, 20),

      '&.active': {
        color: 'black',
        background: '#FFFFFF',
        border: '0.5px solid rgba(0, 0, 0, 0.04)',
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
        ...fontHelpers(700, 13, 20),
      }
    }}
      onClick={props.onClick}
      className={props.isActive ? "active" : ""}
    >
      {props.children}
    </Button>
  )
}

function PayButtonDialog(props) {
  const { state, dispatch } = useContext(UserContext);
  const [addr, setAddr] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("bto");
  const [payOrReceive, setPayOrReceive] = useState('pay');
  const [Validate, setValidate] = useState('');

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { language, switchLanguage } = useLanguage();

  const handleAddressChange = (evt) => {
    setValidate('')
    setAddr(evt.target.value);
  };

  const handleAmountChange = (evt) => {
    setValidate('')
    setAmount(evt.target.value)
  }

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    setAmount("");
  }

  const onClose = (isSuccess) => {
    // Reset state on close.
    setAddr("");
    setCurrency("bto");
    setAmount("");
    setPayOrReceive('pay');
    props.onClose(!!isSuccess);
  }

  const title = (
    <>
      <img src="/assets/logo_small.svg" alt="wallet logo" style={{ display: "inline-block", marginRight: "8px" }} />
      <Typography component="span">{translations[language].pay}</Typography>
    </>
  )
  const styles = {

  };

  useEffect(() => {
    setTimeout(() => {
      if (!state.walletAddress) {
        GetWalletAssetsWrapped(dispatch).then((wallet) => {
        })
      }
    }, 1000);
  }, [dispatch, state.walletAddress]);


  const handleConfirm = (evt) => {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    if (addr === '')
      setValidate('Must enter Address')
    else if (!amount) {
      setValidate('Must enter Amount')
    } else if (amount < 0) {
      setValidate('Amount must be grater than 0')
    } else if (amount % 1 !== 0) {
      setValidate('No Decimal on Amount')
    } else if (!regex.test(addr)) {
      setValidate('Invalid Address')
    } else
      setShowConfirmationDialog(true);  // show the confirmation dialog instead of making the API call directly
  };

  return (
    <>
      <SlideUpDialog
        open={props.open}
        onClose={onClose}
        title={title}
        sx={styles}
      >
        <Box sx={{ mb: '15px' }}>
          <Typography variant="body2">{translations[language].payDescription}</Typography>
        </Box>
        <WalletBox variant="contained" color="primary">
          <Box sx={{ background: '#F7F8FA', display: 'flex', borderRadius: '9px', padding: '1px', }}>
            <SwitchButton
              isActive={payOrReceive === "pay"}
              onClick={() => setPayOrReceive("pay")}
            >{translations[language].pay}</SwitchButton>
            <div style={{ width: '2px' }}></div>
            <SwitchButton
              isActive={payOrReceive === "receive"}
              onClick={() => setPayOrReceive("receive")}
            >
              {translations[language].receive}
            </SwitchButton>
          </Box>
          <WhiteDivider />
          {payOrReceive === 'pay' &&
            <>
              <AddressScanner
                address={addr}
                onChange={handleAddressChange}
                onScan={(data) => { setAddr(data) }}
                title={translations[language].payRecipantAddress}
                inputPlaceHolder={translations[language].payEnterRecipantAddress}
              />

              <SendAmount
                available={state.btoAvailable}
                currency={currency}
                onChangeCurrency={handleCurrencyChange}
                amount={amount}
                onChangeAmount={handleAmountChange}
              />

              <Typography variant="h6" sx={{ fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: '#ffcccb', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'right', alignItems: 'center' }} component="div">
                {Validate !== '' && <ErrorOutlineIcon sx={{ mr: 1 }} />}
                {Validate}
                {Validate === '' && <div style={{ height: '24px', marginRight: '8px' }}></div>}
              </Typography>

              <Button variant="contained" color="secondary"
                sx={{
                  display: 'block',
                  width: '126px',
                  margin: '0 auto',
                }}
                onClick={handleConfirm}>{translations[language].confirm}</Button>
            </>
          }
          {payOrReceive === 'receive' &&
            <>
              <Typography>{translations[language].yourWalletAddress}</Typography>
              <Grid container sx={{ mb: "15px" }}>
                <Grid item xs={7}>
                  <QRCodeImg data={state.walletAddress} convertQRCode={false} dataUrl={state.walletAddressQRCode} style={{ width: "100%" }}></QRCodeImg>
                </Grid>
                <Grid item xs={5} sx={{ pl: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography sx={{ overflowWrap: "break-word", width: "100%" }}>{state.walletAddress}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: "15px" }}></Grid>

                <Grid item xs={7}>
                  <CopyAddressButton ButtonSx={{ width: "100%", py: "12px" }} address={state.walletAddress}></CopyAddressButton>
                </Grid>

                <Grid item xs={5} sx={{ pl: "15px" }}>
                </Grid>
              </Grid>
            </>
          }
        </WalletBox>
      </SlideUpDialog>
      <PaymentConfirmationDialog
        open={showConfirmationDialog}
        amount={amount}
        currency={currency}
        address={addr}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  )

  function PaymentConfirmationDialog(props) {
    const [apiState, setApiState] = useState("init");
    const [serverErrorMessage, setServerErrorMessage] = useState("")

    const handleRealPayment = (evt) => {
      setApiState("loading");
      Pay({
        ccy: currency.toUpperCase(),
        amount: toWei(amount, currency.toUpperCase()),
        address: addr,
      })
        .then((ret) => {
          setApiState("success");
        })
        .catch((err) => {
          console.error(err);
          //setShowConfirmationDialog(false);
          setServerErrorMessage(err.data.message || "Sorry! We cannot process your request at this moment. Please try again later.")
          setApiState("failure");
        })
    }

    const handleOnClose = (evt) => {
      setApiState("init");
      evt.onClose()
    }

    return (
      <>
        <ApiProgressDialog
          open={apiState !== 'init'}
          status={apiState}
          onClose={() => {
            window.location.reload();
          }}
          message={serverErrorMessage}
          successMessage={(
            <Box>
              <img src='/assets/tick.png' alt="tick" style={{
                display: 'block',
                margin: '0 auto',
                width: '70px',
                marginBottom: '38px',
              }} />
              <Typography align='center'
                sx={{ ...fontHelpers(700, 20, 25) }}
              >Pay Successfully!</Typography>
            </Box>
          )}
        />
        <SlideUpDialog
          open={props.open}
          onClose={props.onClose}
          title={translations[language].paymentConfirmation}
        >
          <Typography sx={{ fontSize: '18px' }}>{translations[language].paymentConfirmationDescription}</Typography><br></br><br></br>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography align="left">{translations[language].amount}:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 700 }}>{props.amount}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography align="left">{translations[language].currency}:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 700 }}>{props.currency.toUpperCase()}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography align="left" >{translations[language].payRecipantAddress}:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 700, wordWrap: 'break-word', fontSize: '12px' }}>{props.address}</Typography>
            </Grid>
          </Grid>
          <Box sx={{ marginTop: '20px' }}>
            <Button variant="contained" color="primary" onClick={handleRealPayment}>{translations[language].yesConfirm}</Button>
            <Button variant="text" onClick={handleOnClose}>{translations[language].cancel}</Button>
          </Box>
        </SlideUpDialog>
      </>
    );
  }
}

export default Navbar;