import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";



// this fuction clear the login session after 1 hour
//window.setTimeout(() => {
//  localStorage.removeItem("AuthToken");
//  localStorage.removeItem("DisplayCurrency")
//}, 3600000);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
