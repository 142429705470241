import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
    TextField,
    CircularProgress
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import theme from '../Themes'

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

import { PageHeader } from './Shared.jsx';

function ViewCart() {
    const location = useLocation();
    const history = useHistory();
    const { language, switchLanguage } = useLanguage();
    const [productsToCheckout, setProductsToCheckout] = useState([]);
    const [productsSubtotal, setProductsSubtotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        let localCart
        try {
            localCart = JSON.parse(localStorage.getItem("Cart"))
        } catch { }
        if (location.state?.products) {
            const products = location.state?.products
            if (products) {
                fetchProductDetails(products);
            }
        } else if (localCart && localCart.products && localCart.products.length > 0) {
            fetchProductDetails(localCart.products)
        } else {
            history.push('/store');
        }
    }, [location, history]);

    useEffect(() => {
        const newSubtotal = productsToCheckout.reduce((acc, product) => {
            return acc + (product.price * product.quantity);
        }, 0);
        setProductsSubtotal(newSubtotal);
    }, [productsToCheckout]);

    const fetchProductDetails = async (updatedProducts) => {
        const payload = updatedProducts.map((product) => ({
            productId: product.id,
            quantity: product.quantity,
        }));
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/core/store/list`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ products: payload }), // Sending the products array in the request body
            });

            if (!response) throw new Error('Server responded with an error.');

            const data = await response.json();

            if (data.products) {
                setProductsToCheckout(
                    data.products.map((product) => {
                        return {
                            ...product
                        }
                    })); // Setting the response data to the state
            } else if (data.products.length < 1) {
                localStorage.removeItem("Cart");
                history.push('/store');
            }
            if (data.subtotal) {
                setProductsSubtotal(data.subtotal.hkd)
            }

            // If any product has statusCode 1, handle the out of stock situation
            const outOfStock = data.products.some((product) => product.statusCode === 1);
            if (outOfStock) {
                // Handle the out of stock situation here
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };

    const debounceTimeoutRef = useRef(null);

    const debouncedFetchProductDetails = (updatedProducts) => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(async () => {
            await fetchProductDetails(updatedProducts);
            setLoading(false);
        }, 1000);
    };

    const handleQuantityChange = (productId, change) => {
        // Update quantities immediately for UI purposes
        setLoading(true);
        setError('');
        const updatedProducts = productsToCheckout.map((product) => {
            if (product.id === productId) {
                const updatedQuantity = Math.max(0, product.quantity + change);
                return { ...product, quantity: updatedQuantity };
            }
            return product;
        }).filter((product) => { return product.quantity > 0 })
        window.localStorage.setItem("Cart", JSON.stringify({ products: updatedProducts }))
        setProductsToCheckout(updatedProducts);
        debouncedFetchProductDetails(updatedProducts);
    }

    const refetchProductDetails = async () => {
        const payload = productsToCheckout.map(product => ({
            productId: product.id,
            quantity: product.quantity,
        }));
        await fetchProductDetails(payload);
    };

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

    const handleCheckout = async () => {
        setLoading(true);
        setError('');

        try {
            const Auth = localStorage.getItem("AuthToken");
            // Start both operations simultaneously
            const fetchSessionPromise = fetch(`${process.env.REACT_APP_BASE_URL}/core/store/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': Auth,
                },
                body: JSON.stringify({
                    products: productsToCheckout,
                    locale: language
                }),
            });

            const stripePromiseResult = stripePromise;

            // Wait for both promises to settle
            const [response, stripe] = await Promise.all([fetchSessionPromise, stripePromiseResult]);

            // Check if the fetch request was successful before proceeding to convert to JSON
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            // Convert the response to JSON and redirect to checkout
            const data = await response.json();
            console.log(data);
            stripe.redirectToCheckout({ sessionId: data.sessionId });
        } catch (err) {
            // Handle errors from both promises
            setError(err.message);
        } finally {
            //setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme.wallet}>
            <Box className="wallet-app-page"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',

                    maxWidth: '600px', margin: 'auto',

                    backgroundColor: "#F4F4F4",
                    width: "100%",
                    minHeight: "100vh",
                    paddingTop: "72px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingBottom: "150px",
                }}
            >
                <PageHeader auth={true} title={translations[language].myCart} showLogout={true} sx={{ width: "100%" }} />
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        mb: "68px",
                    }}
                >
                    <Box >
                        <Grid container spacing={3}>
                            <Grid item>
                                {productsToCheckout.map((product, index) => (
                                    <Card variant="outlined" sx={{ mb: 2, p: 2, display: 'flex', flexDirection: 'column', ...(product.statusCode === 1 ? { bgcolor: '#ffcccc' } : {}) }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={product?.images && product.images[0]?.base64} alt={product.name} style={{ marginRight: '16px', maxWidth: '150px' }} />
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant="h6">{product.name[language]}</Typography>
                                                <Typography color="text.secondary">{product.description[language]}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: 200 }}>
                                                <IconButton size="small" onClick={() => handleQuantityChange(product.id, -1)}>
                                                    <RemoveIcon />
                                                </IconButton>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={product.quantity}
                                                    onChange={(e) => {
                                                        const newQuantity = parseInt(e.target.value, 10);
                                                        if (!isNaN(newQuantity) && newQuantity >= 0) {
                                                            handleQuantityChange(product.id, newQuantity - product.quantity);
                                                        }
                                                    }}
                                                    sx={{ mx: 1 }} // Add margin around the TextField
                                                />
                                                <IconButton size="small" onClick={() => product.statusCode !== 1 && handleQuantityChange(product.id, 1)}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="body2" style={{ textDecoration: 'line-through' }}>
                                                    {product.originalPrice * product.quantity}
                                                </Typography>
                                                <Typography variant="body1" color="primary">
                                                    {product.price * product.quantity} {product.currency.toUpperCase()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Card>
                                ))}
                            </Grid>
                        </Grid>
                        {productsToCheckout.length > 0 ? (
                            <Grid item xs={12} md={4}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h7" gutterBottom>{translations[language].orderSummary}</Typography>
                                        <Typography variant="h6">{translations[language].subTotal}: HK${productsSubtotal}</Typography>
                                        {loading ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{ mt: 2 }}
                                                onClick={() => handleCheckout()}
                                                disabled={loading}
                                            >
                                                Checkout
                                            </Button>
                                        )}
                                        <Typography variant="caption" display="block" align="center" sx={{ mt: 2 }}>
                                            🔒 Secure Checkout
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ) : (null)}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default ViewCart;
