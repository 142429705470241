import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../App"
import { useHistory } from 'react-router-dom';

import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import theme from '../Themes'

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

import { PageHeader } from './Shared.jsx';

function Orders() {
    const history = useHistory();

    const { dispatch } = useContext(UserContext);
    const { language, switchLanguage } = useLanguage();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch({ type: "SHOW_NAVBAR" });

        fetchOrders()
    }, [dispatch])

    const fetchOrders = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/core/order/list`, {
                method: 'POST', // or 'POST' if it should be a POST request
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem("AuthToken"), // Assuming the token is stored in localStorage
                },
            });
            const data = await response.json();
            if (response.ok) {
                setOrders(data.results); // Assuming the data comes in a `results` key
            } else {
                // Handle any errors, e.g., show a message to the user
                console.error('Failed to fetch orders:', data.message);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false); // Stop loading whether there was an error or not
        }
    }

    return (
        <ThemeProvider theme={theme.wallet}>
            <Box className="wallet-app-page"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',

                    maxWidth: '600px', margin: 'auto',

                    backgroundColor: "#F4F4F4",
                    width: "100%",
                    minHeight: "100vh",
                    paddingTop: "72px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingBottom: "150px",
                }}
            >
                <PageHeader auth={false} title={translations[language].store} showLogout={true} sx={{ width: "100%" }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', width: "100%", padding: "16px" }}>
                    {loading ? (
                        <CircularProgress /> // Show spinner when loading
                    ) : orders.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Products</TableCell>
                                        <TableCell align="right">Total Price</TableCell>
                                        <TableCell align="right">Buyer</TableCell>
                                        <TableCell align="right">Order ID</TableCell>
                                        {/* Add more headers if needed */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((order) => (
                                        <TableRow
                                            key={order?.orderId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="right">
                                                {order?.products?.map((product, index) => (
                                                    <Box key={index}>
                                                        <Typography variant="body2">{product.name.en}</Typography>
                                                        <Typography variant="body2">{`${product.quantity} x $${product.price} ${product.currency.toUpperCase()}`}</Typography>
                                                    </Box>
                                                ))}
                                            </TableCell>
                                            <TableCell align="right">
                                                {order?.products?.reduce((total, product) => total + (product.price * product.quantity), 0)}
                                            </TableCell>
                                            <TableCell align="right">{order?.buyer?.email}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {order?.orderId?.substring(0, 20)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography>No orders found.</Typography> // Show message only after loading is complete
                    )
                    }
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default Orders;
