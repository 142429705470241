import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from "../App"

import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import {CoinChartDialog} from './Dialogs'
import { walletAppPage, PageHeader, getCoinIcon } from './Shared'
// import { GetHistoricalCoinData } from '../functions/network'
import { GetHistoricalCoinDataWrapped } from '../functions/functions'

function floatToFixed(f, dp) {
  if (!f) {
    return "";
  }

  if (dp === undefined) {
    dp = 2;
  }

  return f.toFixed(dp);
}

function MarketPriceItem(props) {
  let icon = getCoinIcon(props.coinType)
  let iconWidth = 28;

  const rowStyles = {
      display: "flex",
      alignItems: "flexStart",
      justifyContent: "space-between",
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '13px',
      lineHeight: '16px',
  };

  const changePercent = props.change / props.price * 100.0;
  let arrow = "";
  let changeTextColor = "#999999"
  if (props.change > 0) {
    arrow = "↗ ";
    changeTextColor = "#00BCD4";
  } else if (props.change < 0) {
    arrow = "↘ ";
    changeTextColor = "#E4392D";
  }

  const changePercentText = `${arrow}${floatToFixed(changePercent, 2)}%`;

  return (
    <Grid item xs={12}>
      <Button
        sx={{
          width: "100%",
          marginBottom: "15px",
          backgroundColor: "white",
          borderRadius: "15px",
          boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",

          "&:hover": {
            backgroundColor: "white",
          }
        }}
        onClick={props.onClick}
      >
        <Box sx={{width: `${iconWidth}px`}}>{icon}</Box>
        <Box sx={{width: `calc(100% - ${iconWidth}px)`, paddingLeft: "10px"}}>
          <Box
              sx={{
                ...rowStyles,
                color: "black"
              }}
          >
            <Typography>{props.coinName}</Typography>
            <Typography>${floatToFixed(props.price, 4)}</Typography>
          </Box>
          <Box
            sx={{
              ...rowStyles,
              color: "#999999"
            }}
          >
            <Typography>{props.coinShortName}</Typography>
            <Box>
              <Typography component="span" sx={{color: changeTextColor,}}>{changePercentText}</Typography>
              <Typography component="span" sx={{marginLeft: "4px"}}>in {floatToFixed(props.changeAgo / 60.0 / 1000.0, 0)}m ago</Typography>
            </Box>
          </Box>
        </Box>
      </Button>
    </Grid>
  );
}

function MarketPrices(props) {
  let items = props.items.map((item, i) => {
    return <MarketPriceItem key={i} {...item} onClick={(e) => props.onClick(e, item)} />;
  });

  return (
    <Grid container sx={{width: '100%'}}>
      {items}
    </Grid>
  )
}

/**
 *
 * @param {*} prices
 * @param {*} minTimeDiff in milliseconds.
 * @returns
 */
function getPriceChange(prices, minTimeDiff) {
  if (prices === undefined || prices === null || prices.length <= 1) {
    return {change: 0, changeAgo: 0};
  }

  let [latestTimestamp, latestPrice] = prices[prices.length - 1];
  let ts, p;
  let change = 0, changeAgo = 0;
  for (let i = prices.length - 2 ; i >= 0 ; i--) {
    [ts, p] = prices[i];
    if (latestTimestamp - ts >= minTimeDiff) {
      change = latestPrice - p;
      changeAgo = latestTimestamp - ts;
      break;
    }
  }

  return {
    change, changeAgo
  }
}

const coins = ["ethereum", "tether"];

function Markets() {
  const history = useHistory();
  const [fetching, setFetching] = useState({ethereum: false, tether: false});
  const [shouldShowChartDialog, setShouldShowChartDialog] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState({});

  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    const Auth = localStorage.getItem("AuthToken");
    if (Auth === '' || Auth === null) {
      history.push('/');
    }
  });

  useEffect(() => {
    dispatch({type: "SHOW_NAVBAR"});
  }, [dispatch]);

  useEffect(() => {
    coins.forEach(coin => {
      // debugger
      if (!fetching[coin]) {
        setFetching((prevState) => ({
          ...prevState,
          [coin]: true,
        }));
        GetHistoricalCoinDataWrapped(coin, 1, state, dispatch)
          .then(data => {

          })
          .catch(err => {
            console.log({ 'error returned => ': err });
          });
      }
    });
  });

  function _getPirceData(prices) {
    return {
      price: prices ? prices[prices.length - 1][1] : null,
      historicalData: prices,
      ...getPriceChange(prices, 30*60*1000),
    }
  }

  let items = [{
    coinType: "eth",
    coinName: "Ethereum",
    coinShortName: "ETH",
    ..._getPirceData(state.historicalData.ethereum[1].prices),
  }, {
    coinType: "usdt",
    coinName: "USDT",
    coinShortName: "USDT",
    ..._getPirceData(state.historicalData.tether[1].prices),
  }]

  const handlePriceItemClick = (e, item) => {
    setSelectedCoin(item);
    setShouldShowChartDialog(true);
  }

  const handleChartDialogClose = () => {
    setSelectedCoin({});
    setShouldShowChartDialog(false);
  }


  return (
    <walletAppPage>
      <PageHeader title="Markets" showLogout={true} sx={{width: "100%"}} />
      {/* As of 20220726, we have two coins only. no need to search.  */}
      {/* <SearchBox /> */}
      <MarketPrices items={items}
        onClick={handlePriceItemClick}
      >
      </MarketPrices>

      <CoinChartDialog
        open={shouldShowChartDialog}
        onClose={handleChartDialogClose}
        coin={selectedCoin}
      ></CoinChartDialog>
    </walletAppPage>
  );
}

export default Markets;