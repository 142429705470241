import Momenttz from 'moment-timezone';
import { fromWei } from './functions';

export const callApi = ({body, path, parserOptions}) => {
  const Auth = localStorage.getItem("AuthToken");
  const options = {
    method: "POST",
    mode: 'cors',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': Auth,
    },
    body: JSON.stringify(body)
  };

  // Making Request Here
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + path, options)
    .then(response => response.json())
    .then(async data => {
      if (data.code !== 0) {
        console.error("data.code is non-zero %d", data.code);
        return reject({
          reason: "data.code is non-zero.",
          data,
        })
      }

      if (!data.results) {
        console.error("Unable to parse API response! Expecting `.results` in the response.");
        return reject({
          reason: "Unable to parse API response! Expecting `.results` in the response.",
          data,
        });
      }

      if (   (parserOptions.isList === false && !data.results[0])
          || (parserOptions.isList === true && parserOptions.canEmpty === false && !data.results[0])
        ) {

        return reject({
          reason: "unable to parse API response. Expecting `.results` to be non-empty array.",
          data,
        });
      }

      if (parserOptions.dataValidator) {
        const validOrErrorMessage = parserOptions.dataValidator(data);
        if (validOrErrorMessage !== true) {
          return reject({
            reason: validOrErrorMessage,
            data,
          })
        }
      }

      resolve(data);
    })
    .catch(err => {
        console.log({ 'error returned => ': err });
        //setWalletAddress('error unable to get address');
        reject(err)
    })
  });
}

function SimpleApi(path, options) {
  if (!options) {
    options = {
      isList: false,
      canEmpty: false,
    }
  }

  return (body) => {
    return new Promise((resolve, reject) => {
      callApi({
        path,
        body,
        parserOptions: options,
      }).then((data) => {
        if (options.isList) {
          return resolve(data.results);
        }
        return resolve(data.results[0]);
      }).catch((err) => {
        reject(err);
      });
    });
  }
}

export const GetUser = SimpleApi('/core/account/getUser');

export const GetWalletAssets = SimpleApi('/core/wallet/getWalletAsset');

export const GenerateAuthenticatorKey = SimpleApi('/core/account/genAuthenticatorKey');

export const SetAuthenticator = SimpleApi('/core/account/setUser');

export const GetHistoricalCoinData = (coin, interval) => {
  if (!interval || interval < 0) {
    interval = 1;
  }

  return new Promise((resolve, reject) => {
    fetch(`https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=USD&days=${interval}`)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
}

export const GetExchangeRate = (refCurrency) => {
  return new Promise((resolve, reject) => {
    fetch(`https://api.coinbase.com/v2/exchange-rates?currency=${refCurrency.toUpperCase()}`)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
}

export const GetSwapQuote = SimpleApi('/core/swap/quote');

export const CreateSwapOperation = SimpleApi('/core/swap/swap', {dataValidator: (data) => {
  if (data.message.toLowerCase() !== "success") {
    return data.message;
  }
  return true;
}});

export const CheckSwapStatus = SimpleApi('/core/swap/checkSwapStatus');

function isStakingRelatedTransaction(tran) {
  return tran.Type === 'staking' || tran.Type === 'unstake'
}

function remapTransactionField(tran) {
  return {
    time: Momenttz.tz(tran.Time, "UTC"),
    currency: tran.Currency,
    type: tran.type,
    amount: fromWei(tran.Amount, tran.Currency),
    duration: tran.Duration,
    creditOrDebit: tran['Credit/Debit'],
  }
}

export const GetTransactionLog = (coinType) => {
  coinType = coinType.toUpperCase();
  return SimpleApi("/core/wallet/getTransactionLog", {isList: true, canEmpty: true})()
    .then((txs) => {
      return txs
        .filter((tx) => {
          return (
            (tx.Currency === coinType) &&
            !isStakingRelatedTransaction(tx)
          );
        })
        .map(remapTransactionField);
    });
};

export const GetPlans = SimpleApi("/core/earn/getUserPlans", {isList: true, canEmpty: false});

export const SetUserPlan = SimpleApi("/core/earn/setUserPlan");
export const GetUserStakeHistory = () => {
  return SimpleApi("/core/wallet/getTransactionLog", {
    isList: true, canEmtpy: true
  })().then((data) => {
    return data
      .filter(isStakingRelatedTransaction)
      .map(remapTransactionField)
  });
}

export const Pay = SimpleApi("/core/wallet/pay", {dataValidator: (data) => {
  if (data.message.toLowerCase() !== "success") {
    return data.message;
  }

  return true;
}});

export const Withdraw = SimpleApi("/core/wallet/requestwithdraw");

export const UpdateFullName = ({firstName, lastName}) => {
  return SimpleApi("/core/account/setUser")({
    firstName, lastName,
  });
};