const SetInitialState = () => {
  const Auth = localStorage.getItem("AuthToken");
  return {
    showNavbar: false,
    loggedIn: !(Auth === "" && Auth === null),
    historicalData: {
      ethereum: {1: {}, 30: {}, 365: {}},
      tether: {1: {}, 30: {}, 365: {}},
    },
  };
};

export const initialState = SetInitialState();

export const reducer = (state, action) => {
  if (action.type === "LOGIN") {
    return {
      ...state,
      loggedIn: action.payload
    };
  }

  if (action.type === "HIDE_NAVBAR") {
    return {
      ...state,
      showNavbar: false,
    }
  }

  if (action.type === "SHOW_NAVBAR") {
    return {
      ...state,
      showNavbar: true,
    }
  }

  if (action.type === 'SET_WALLET_ADDRESS') {
    return {
      ...state,
      walletAddress: action.payload,
    }
  }

  if (action.type === 'SET_WALLET_ADDRESS_QRCODE') {
    return {
      ...state,
      walletAddressQRCode: action.payload,
    }
  }

  const COINS = ["BTO", "USDT"];
  const SET_COIN_BALANCE_RE = new RegExp(`^SET_(${COINS.join("|")})_BALANCE$`);
  let match = SET_COIN_BALANCE_RE.exec(action.type);
  if (match) {
    return {
      ...state,
      [`${match[1].toLowerCase()}Balance`]: action.payload
    }
  }

  const SET_COIN_AVAILABLE_RE = new RegExp(`^SET_(${COINS.join("|")})_AVAILABLE$`);
  match = SET_COIN_AVAILABLE_RE.exec(action.type);
  if (match) {
    return {
      ...state,
      [`${match[1].toLowerCase()}Available`]: action.payload
    }
  }

  if (action.type === "SET_HISTORICAL_DATA") {
    let ret = {
      ...state,
    }
    const {coin, interval, fetchTimestamp, data} = action.payload;
    ret.historicalData[coin][interval] = {
      fetchTimestamp,
      ...data,
    }
    return ret;
  }

  console.error("Unknown action '%s'", action)
  return state;
};
