import web3 from 'web3'
import QRCode from 'qrcode'
import { GetWalletAssets, GetHistoricalCoinData } from './network'

export const toWei = ((amount, ccy) => {
    if (ccy === 'BTO')
        return amount//web3.utils.toWei(amount, 'ether')
    else if (ccy === 'USDT')
        return web3.utils.toWei(amount, 'mwei')

    return "toWei(amount, 'ether')"
})

export const fromWei = ((amount, ccy) => {
    if (ccy === 'BTO')
        return amount ? amount : 0//web3.utils.fromWei(amount, 'ether')
    else if (ccy === 'USDT')
        return web3.utils.fromWei(amount, 'mwei')

    return "toWei(amount, 'ether')"
})

export const isAuth = ((history) => {
    return new Promise(async (resolve, reject) => {
        const Auth = localStorage.getItem("AuthToken");
        if (Auth === "" || Auth === null) {
            window.localStorage.removeItem("AuthToken");
            window.localStorage.removeItem("DisplayCurrency");
            history.push('/');
            return;
        }
        else {
            const options = {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-auth-token': Auth,
                }
            };

            // Making Request Here
            fetch(process.env.REACT_APP_BASE_URL + "/core/account/getUser", options)
                .then(response => response.json()).then(data => {
                    if (data.results && data.results[0] && data.results[0].name) {
                        return resolve(data.results[0])
                    } else {
                        window.localStorage.removeItem("AuthToken");
                        window.localStorage.removeItem("DisplayCurrency");
                        history.push('/');
                        reject()
                    }
                }).catch((e) => {
                    window.localStorage.removeItem("AuthToken");
                    window.localStorage.removeItem("DisplayCurrency");
                    history.push('/');
                    reject(e)
                    return
                })
            setTimeout(function () {
                reject('timeout')
            }, 10000);
        }
    })
})

export function toShortAddress(address) {
    return address ? address.substring(0, 5) + "..." + address.substring(address.length - 5) : "";
}

export function getCoinInfo(coinType) {
    if (!coinType) {
        return {};
    }

    switch (coinType) {
        case "bto":
            return {
                coinType: "bto",
                coinName: "BTO",
                coinUnit: "BTO",
            };
        case "usdt":
            return {
                coinType: "usdt",
                coinName: "USDT",
                coinUnit: "USDT",
            }
        default:
            console.error("Unsupported coinType");
            return {};
    }
}

export const sleep = ((ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
})

export function fontHelpers(fontWeight, fontSize, lineHeight) {
    return {
        fontWeight,
        fontSize: `${fontSize}px`,
        lineHeight: `${lineHeight}px`,
    }
}

export function GetWalletAssetsWrapped(dispatch) {
    return GetWalletAssets()
        .then(wallet => {
            if (wallet.address) {
                dispatch({
                    type: "SET_WALLET_ADDRESS",
                    payload: wallet.address
                })

                QRCode.toDataURL(wallet.address, function (err, url) {
                    dispatch({
                        type: "SET_WALLET_ADDRESS_QRCODE",
                        payload: url
                    })
                })
            }

            if (wallet.btoBalance) {
                dispatch({
                    type: "SET_BTO_BALANCE",
                    payload: fromWei(wallet.btoBalance, "BTO"),
                })
            }

            if (wallet.btoAvailableBalance) {
                dispatch({
                    type: "SET_BTO_AVAILABLE",
                    payload: fromWei(wallet.btoAvailableBalance, "BTO"),
                });
            }

            if (wallet.usdtBalance) {
                dispatch({
                    type: "SET_USDT_BALANCE",
                    payload: fromWei(wallet.usdtBalance, "USDT"),
                });
            }

            if (wallet.usdtAvailableBalance) {
                dispatch({
                    type: "SET_USDT_AVAILABLE",
                    payload: fromWei(wallet.usdtAvailableBalance, "USDT"),
                });
            }

            return wallet;
        }).catch(e => console.error(e))
}

export function GetHistoricalCoinDataWrapped(coin, interval, state, dispatch) {
    const ts = new Date().getTime();
    if (ts - state.historicalData[coin][interval].fetchTimestamp < 5 * 60 * 1000) {
        return Promise.resolve((resolve) => {
            resolve(state.historicalData[coin][interval].prices);
        });
    }

    return GetHistoricalCoinData(coin, interval)
        .then(data => {
            dispatch({
                type: 'SET_HISTORICAL_DATA',
                payload: {
                    coin, interval, fetchTimestamp: ts, data
                }
            })
            return data;
        });
}