import React, { useState } from 'react';
import Box from "@mui/material/Box";
import './Navbar.css';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Link, useHistory } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InputLabel, OutlinedInput, Divider, Grid } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../Themes'

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

const styles = {
    formInputValidStyle: {
        // borderBottom: '2px solid #A1A8B2',
    },

    formInputInvalidStyle: {
        // borderBottom: '2px solid red',
    }
};

function ForgotPassword() {
    const history = useHistory();
    const [Email, setEmail] = useState();
    const [Validate, setValidate] = useState(true);
    const [ReponseBoolen, setReponseBoolen] = useState(false);
    const [ReponseString, setReponseString] = useState('');
    const { language, switchLanguage } = useLanguage();

    const Focus = () => {
        setValidate(true);
        setReponseBoolen(false);
    }

    const SetStatesToInitial = () => {
        setEmail();
    }


    const Submit = (e) => {
        if (!Email) {
            setValidate(false);
            setTimeout(() => {
                setValidate(true);
            }, 5000);
            return;
        }

        const options = {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": Email,
            })
        };

        // Making Request Here
        fetch(process.env.REACT_APP_BASE_URL + "/core/account/forgotPassword", options)
            .then(response => response.json()).then(data => {
                alert('Submitted, if email match in our database, we will send reset link to your email.')
                SetStatesToInitial();
                history.push('/');
                return
            }).catch(err => {
                console.log(err);
                setReponseBoolen(true);
                setReponseString('Connection Lost.');
            })

        setTimeout(() => {
            setReponseBoolen(false);
        }, 5000)

    };

    return (
        <ThemeProvider theme={theme.login}>
            <Box component={'div'} className="wallet-app-page"
                sx={{
                    display: {
                        md: 'flex',
                        xs: 'flex'
                    },
                    alignItems: 'center',
                    flexDirection: 'column',
                    pb: "150px",
                    maxWidth: '600px',
                    margin: 'auto'
                }}
            >
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        mb: "68px",
                    }}
                >
                    <Typography variant="h6" component="div"
                        sx={{
                            fontSize: '24px',
                            fontWeight: '700',
                            color: '#000',
                        }}
                    >
                        {translations[language].forgotPassword}?
                    </Typography>
                </Box>
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: "70px"
                    }}
                >
                    <img style={{ width: '220px', }} src="assets/logo.svg" alt="logo" />
                </Box>
                <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', }} >
                    <Box
                        id="sign-in-form"
                        component="form"
                        method='POST'
                        noValidate
                        autoComplete="off"
                        sx={{ width: '100%', color: '#FFFFFF', fontSize: '20px' }}
                        onSubmit={Submit}
                    >
                        <FormControl>
                            <InputLabel shrink htmlFor="sign-in-email">
                                {translations[language].email}
                            </InputLabel>
                            <OutlinedInput
                                id="sign-in-email"
                                name="eamil"
                                placeholder='Enter your Email'
                                autoComplete='off'
                                noValidate
                                type="text"
                                sx={Validate ? styles.formInputValidStyle : styles.formInputInvalidStyle}
                                onFocus={Focus}
                                value={Email}
                                onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                            />
                        </FormControl>

                        <Typography variant="h6" sx={Validate ? { display: 'none' } : { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' }} component="div">
                            <ErrorOutlineIcon sx={{ mr: 1 }} />{translations[language].fillAllFields}
                        </Typography>

                        <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                            <ErrorOutlineIcon sx={{ mr: 1 }} />{ReponseString}
                        </Typography>

                        <Button
                            id="submit"
                            onClick={Submit}
                            variant="contained"
                            sx={{
                                backgroundColor: theme.login.palette.primary.main, mt: "48px",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {translations[language].submit}
                        </Button>

                        <Divider variant="middle" sx={{ mt: "50px" }} />
                        <Grid container id="bottom-actions" sx={{ textAlign: "center", }}>
                            <Grid item xs={6}>
                                <Link className='link' to={'/'} >
                                    {translations[language].login}
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link className='link' to={'/createAccount'} >
                                    {translations[language].createAccount}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    )
}

export default ForgotPassword;