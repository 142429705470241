import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../App"
import { useHistory } from 'react-router-dom';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import theme from '../Themes'

import { ReactComponent as LogoutIcon } from '../icons/icon-logout.svg';
import { LogOutDialog } from './Dialogs'

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

const IconHeight = 12;
const ButtonHeight = 16;
const Space = 16; // Space between Icon and Button

const PageHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%', display: 'flex', alignItems: 'flex-end',
  marginBottom: '20px',

  "& .MuiTypography-root": {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "30px",
    width: "calc(100% - 50px)",
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  width: '100px', height: `${IconHeight}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  height: `${ButtonHeight}px`, width: "80px",
  color: "black",
  fontWeight: "700",
}));

export function PageHeader(props) {
  const { dispatch } = useContext(UserContext);
  const history = useHistory();

  const [shouldOpenLogoutDialog, setShouldOpenLogoutDialog] = useState(false);

  const logOut = () => {
    localStorage.removeItem("AuthToken");
    localStorage.removeItem("DisplayCurrency")
    dispatch({ type: "LOGIN", payload: false });
    history.push('/');
  }

  const onClickLogoutButton = () => {
    // logOut();
    setShouldOpenLogoutDialog(true);
  };

  let logoutButton = null;
  let pageHeaderHeight = 30;
  let height = null;
  if (props.showLogout) {
    logoutButton = (
      <LogoutButton startIcon={<LogoutIcon />} onClick={onClickLogoutButton}>Logout</LogoutButton>
    )
    pageHeaderHeight = height = IconHeight + ButtonHeight + Space;

  }
  return (
    <PageHeaderBox component="div" sx={{ height: `${pageHeaderHeight}px` }}>
      <Typography variant="h1" component="div">
        {props.title}
      </Typography>
      <IconContainer component="div" sx={{ height: `${height}px` }}>
        {logoutButton}
        <img style={{ width: '50px' }} src="assets/logo_small.svg" alt="logo" />
      </IconContainer>
      <LogOutDialog
        open={shouldOpenLogoutDialog}
        onClose={() => { setShouldOpenLogoutDialog(false) }}
        onLogout={() => { logOut(); }}
      />
    </PageHeaderBox>
  );
}

function GetInTouch() {
  const { language } = useLanguage();

  return (
    <Box
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
        width: "100%",
        overflow: "hidden",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.login.palette.primary.main,
          padding: "10px 24px"
        }}
      >
        <Typography sx={{ color: "white", fontSize: '24px', fontWeight: '700', backgroundColor: '#2A1662' }}>{translations[language].aboutEmailTitle}</Typography>
      </Box>
      <Box
        sx={{
          padding: "18px 24px",
        }}
      >
        <Box to="mailto:admin@boredtech.xyz"
          style={{
            backgroundColor: theme.login.palette.primary.main,
            borderRadius: "15px",
            display: 'block',
            textAlign: 'center',
            color: 'white',
            padding: '8px'
          }}
        >
          <img src="/assets/icon-mail.svg" alt="mail icon" style={{ marginRight: "5px", display: "inline-block" }}></img>
          <Typography component="span">
            <a href="mailto:admin@boredtech.xyz" style={{ textDecoration: 'none', color: 'inherit' }}>
              admin@boredtech.xyz
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

function AboutUs() {
  const { dispatch } = useContext(UserContext);
  const { language, switchLanguage } = useLanguage();

  useEffect(() => {
    dispatch({ type: "SHOW_NAVBAR" });
  }, [dispatch])

  return (
    <ThemeProvider theme={theme.wallet}>
      <Box className="wallet-app-page"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',

          maxWidth: '600px', margin: 'auto',

          backgroundColor: "#F4F4F4",
          width: "100%",
          minHeight: "100vh",
          paddingTop: "72px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "150px",
        }}
      >
        <PageHeader title={translations[language].about} showLogout={true} sx={{ width: "100%" }} />
        <Box component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            mb: "68px",
          }}
        >
        </Box>
        <Box component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: "70px"
          }}
        >
          <img style={{ width: '220px' }} src="/assets/logo-no-shadow.svg" alt="logo"></img>
        </Box>
        <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', }} >
          <GetInTouch />
          <Box
            sx={{
              width: "100%",
              backgroundColor: theme.login.palette.primary.main,
              borderRadius: "15px",
              boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
              padding: "21px 18px",
              ".MuiTypography-root": {
                marginBottom: "20px",
                color: 'white',
                fontSize: '13px',
                lineHeight: '16px',
                textAlign: 'left'
              },
              color: 'white'
            }}
          >
            <Typography variant="body1">
              {translations[language].aboutEmailTitle}
            </Typography>
            {translations[language].aboutDescriptionPoints.split('\n').map((point, index) => (
              <Typography key={index}>{point}</Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default AboutUs;
