import { useContext, useState, useEffect } from 'react';
import { UserContext } from "../App";
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Box from "@mui/material/Box";
import Fade from '@mui/material/Fade';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import { Divider, Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

// ---
import { isAuth } from '../functions/functions'
import { ReactComponent as LogoutIcon } from '../icons/icon-logout.svg';
import { LogOutDialog, QRCodeScanner, SlideUpDialog, TokenPickerDialog } from './Dialogs'
import { fontHelpers, getCoinInfo } from '../functions/functions';

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

import theme from '../Themes'

const IconHeight = 12;
const ButtonHeight = 16;
const Space = 16; // Space between Icon and Button

const PageHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%', display: 'flex', alignItems: 'flex-end',
  marginBottom: '20px',

  "& .MuiTypography-root": {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "30px",
    width: "calc(100% - 50px)",
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  width: '100px', height: `${IconHeight}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  height: `${ButtonHeight}px`, width: "80px",
  color: "black",
  fontWeight: "700",
}));


export function PageHeader(props) {
  const { dispatch } = useContext(UserContext);
  const { language } = useLanguage();
  const history = useHistory();

  const [shouldOpenLogoutDialog, setShouldOpenLogoutDialog] = useState(false);

  useEffect(() => {
    dispatch({ type: "SHOW_NAVBAR" });
    if (props.auth)
      isAuth(history)
  }, [dispatch])

  const logOut = () => {
    localStorage.removeItem("AuthToken");
    localStorage.removeItem("DisplayCurrency")
    dispatch({ type: "LOGIN", payload: false });
    history.push('/');
  }

  const onClickLogoutButton = () => {
    // logOut();
    setShouldOpenLogoutDialog(true);
  };

  let logoutButton = null;
  let pageHeaderHeight = 30;
  let height = null;
  if (props.showLogout) {
    logoutButton = (
      <LogoutButton startIcon={<LogoutIcon />} onClick={onClickLogoutButton}>{translations[language].logout}</LogoutButton>
    )
    pageHeaderHeight = height = IconHeight + ButtonHeight + Space;

  }
  return (
    <PageHeaderBox component="div" sx={{ height: `${pageHeaderHeight}px` }}>
      <Typography variant="h1" component="div">
        {props.title}
      </Typography>
      <IconContainer component="div" sx={{ height: `${height}px` }}>
        {logoutButton}
        <img style={{ width: '50px' }} src="assets/logo_small.svg" alt="logo" />
      </IconContainer>
      <LogOutDialog
        open={shouldOpenLogoutDialog}
        onClose={() => { setShouldOpenLogoutDialog(false) }}
        onLogout={() => { logOut(); }}
      />
    </PageHeaderBox>
  );
}

export const WalletAppPage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',

  maxWidth: '600px', margin: 'auto',

  backgroundColor: "#F4F4F4",
  width: "100%",
  minHeight: "100vh",
  paddingTop: "72px",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "150px",
}));

export function getCoinIcon(coinType) {
  if (!coinType) {
    console.debug("coinType is falsy. returing null");
    return null;
  }

  switch (coinType) {
    case "bto":
      return <img src="/assets/icon-bto.png" alt="icon-bto" />;
    case "eth":
      return <img src="/assets/icon-eth.png" alt="icon-eth" />;
    case "usdt":
      return <img src="/assets/icon-usdt.png" alt="icon-usdt" />;
    default:
      console.error("Unsupported coin type %s.", coinType)
      return null;
  };
}

// MuiBox does not support variant.
export function WalletBox(props) {
  let styles = {
    borderRadius: "15px",
    padding: "18px 24px",
  }

  if (props.variant === "contained") {
    styles.boxShadow = "0px 4px 0px rgba(0, 0, 0, 0.8)";

    if (props.color === "primary") {
      styles.backgroundColor = theme.login.palette.primary.main;
      styles.color = "white";
    }
  }

  if (props.variant === "filled") {
    if (props.color === "primary") {
      styles.backgroundColor = '#244CA6';
      styles.color = "white";
    } else if (props.color === "secondary") {
      styles.backgroundColor = 'white';
      styles.color = "black";
    }
  }

  const finalStyles = {
    ...styles,
    ...(props.sx ? props.sx : {}),
  }
  return <Box sx={finalStyles}>{props.children}</Box>
}

export function SourceTokenPicker(props) {
  const [showTokenPicker, setShowTokenPicker] = useState(false);

  let disableAmount = false;
  // ignore all other value. only accept a `true`.
  if (props.disableAmount === true) {
    disableAmount = true;
  }

  let TokenIcon = getCoinIcon(props.tokenType);

  const textSx = {
    ...{
      display: "flex", justifyContent: "space-between",
      mb: '10px',

      '.text': {
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '20px',
      },
      '.value': {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '16px',
      }
    },
    ...(props.textProps && props.textProps.sx ? props.textProps.sx : {}),
  }

  const inputSx = {
    ...{
      backgroundColor: "white",
      borderRadius: "15px",
      padding: "10px 20px",
      display: "flex", alignItem: "stretch",
      width: "100%",
    },
    ...(props.inputProps && props.inputProps.sx ? props.inputProps.sx : {}),
  }

  const availableCurrencies = props.availableCurrencies ? props.availableCurrencies() : [getCoinInfo("bto")];

  const handleClickTokenSelectButton = () => {
    if (!availableCurrencies || availableCurrencies.length <= 1) {
      // do nothing
      return
    }

    // Pop the dialog to pick the currency.
    setShowTokenPicker(true);
  }

  return (
    <>
      {!disableAmount &&
        <Box sx={textSx}>
          <Typography className='text'>{props.availableText || "Amount"}</Typography>
          <Typography className='value'>{props.available} {props.coinUnit}</Typography>
        </Box>
      }
      <Box sx={inputSx}
      >
        <Button
          // onClick={props.onClickTokenPicker}
          onClick={handleClickTokenSelectButton}
          startIcon={TokenIcon}
          endIcon={availableCurrencies && availableCurrencies.length > 1 ? <ExpandMoreIcon /> : null}
          sx={{
            padding: "0",
            minWidth: "0",
            color: "black",
            ".MuiButton-startIcon, .MuiButton-endIcon": {
              margin: "0",
            }
          }}
        ></Button>
        <Input type='number'
          placeholder='0'
          value={props.amount}
          onChange={props.onChangeAmount}
          disableUnderline={true}
          sx={{
            flex: "1", /* so it takes all the remaining space. */
            color: '#244CA6',
            ".MuiInput-input": {
              textAlign: "right",
              ...fontHelpers(700, 16, 20),
            },
          }}
        />
      </Box>
      <TokenPickerDialog
        open={showTokenPicker}
        onClose={() => {
          setShowTokenPicker(false)
        }}
        onSelectCoin={(coinType) => {
          props.onChangeTokenType && props.onChangeTokenType(coinType);
          setShowTokenPicker(false);
        }}
        assets={availableCurrencies}
        selectedCoinType={props.allowReselect ? null : props.tokenType}
      >
      </TokenPickerDialog>
    </>
  )
}

/**
 * This is intended to be used in a WalletBox
 */
export const WhiteDivider = styled(Divider)((theme) => ({
  borderColor: 'white',
  margin: '10px -24px',
}))

export function QRCodeImg(props) {
  const convertQRCode = props.convertQRCode === undefined ? true : props.convertQRCode;

  const [QRCodeData, setQRCodeData] = useState(null);
  const { language, switchLanguage } = useLanguage();

  useEffect(() => {
    if (convertQRCode) {
      QRCode.toDataURL(props.data, (err, url) => {
        if (err) {
          console.error("failed to convert to QR code");
          return;
        }

        setQRCodeData(url);
      });
    }
  }, [props.data, convertQRCode]);

  useEffect(() => {
    if (!convertQRCode && props.dataUrl) setQRCodeData(props.dataUrl)
  }, [props.dataUrl, convertQRCode])

  return (
    <img alt={props.alt || "QR Code"} src={QRCodeData} style={props.style} />
  )
}

export function CopyAddressButton(props) {
  const [showCopiedToolTip, setShowCopiedToolTip] = useState(false);
  const { language, switchLanguage } = useLanguage();

  const btn = props.button ? props.button : (
    <Button
      variant="contained"
      color="secondary"
      sx={props.ButtonSx}
    >{translations[language].copy}</Button>
  )
  return (
    <CopyToClipboard text={props.address} onCopy={() => {
      setShowCopiedToolTip(true);
      setTimeout(() => {
        setShowCopiedToolTip(false);
      }, 3000);
    }}>
      <Tooltip
        open={showCopiedToolTip} title={translations[language].copied + "!"}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          disablePortal: true,
        }}
        TransitionComponent={Fade}
        arrow
        placement="top"
      >
        {btn}
      </Tooltip>
    </CopyToClipboard>
  )
}

export function CurrencyPickerDialog(props) {
  const handleOnClick = (currency) => {
    return (evt) => {
      props.onChange(currency, evt);
    }
  }

  const items = props.availableCurrencies.map((currency, i) => {
    return (
      <Grid item key={i} xs={12}
      >
        <Button
          onClick={handleOnClick(currency)}
          fullWidth
          sx={{
            color: "black",
          }}
          endIcon={props.selected === currency ? <CheckIcon /> : null}
        >
          <Box>{currency.toUpperCase()}</Box>
        </Button>
      </Grid>
    )
  })
  return (
    <SlideUpDialog
      open={props.open}
      onClose={props.onClose}
      title="Display Currency"
    >
      <Grid container sx={{ marginBottom: "30px" }}>
        {items}
      </Grid>
    </SlideUpDialog>
  )
}

export function CurrencyPicker(props) {
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = (evt) => {
    setShowDialog(true);
  }

  const onClose = () => {
    setShowDialog(false);
    if (props.onClose) {
      props.onClose();
    }
  }

  const onChange = (currency, evt) => {
    setShowDialog(false);
    props.onChange(currency, evt);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        sx={{
          minWidth: 0,
          padding: 0,
          ...(props.buttonSX ? props.buttonSX : {})
        }}
      >
      </Button>
      <CurrencyPickerDialog
        open={showDialog}
        onClose={onClose}
        availableCurrencies={props.availableCurrencies}
        onChange={onChange}
        selected={props.selected}
      />
    </>
  )
}

export function SendAmount(props) {
  const { language, switchLanguage } = useLanguage();

  return (
    <Box sx={{ mb: '24px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ ...fontHelpers(300, 16, 20) }}>{translations[language].amount}</Typography>
        <Box sx={{ background: 'Black', display: 'flex', borderRadius: '9px', padding: '1px', }}>
          <SourceTokenPicker
            disableAmount={true}
            amount={props.amount}
            onChangeAmount={props.onChangeAmount}
            tokenType={props.currency}
            onChangeTokenType={props.onChangeCurrency}
            availableCurrencies={props.availableCurrencies}
            allowReselect={true}
          />
        </Box>
      </Box>
    </Box>

  )
}

export function AddressScanner(props) {
  const [showQRCodeScanner, setShowQRCodeScanner] = useState(false);
  const { language, switchLanguage } = useLanguage();

  useEffect(() => {
    return () => {
      setShowQRCodeScanner(false);
    }
  }, [])

  const handleScanBtn = (evt) => {
    // TODO: bring up the QRScaner
    setShowQRCodeScanner(true)
  };

  return (
    <>
      <Box sx={{ mb: '10px' }}>
        <Box sx={{
          display: "flex",
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          mb: '10px',

          '.subtitle': {
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '20px',
          },

          '.ScanQRCodeBtn': {
            borderRadius: '3px',
            width: '80px',
            padding: '0',
            '.MuiButton-startIcon': {
              marginRight: '2px',
            },
            'span.text': {
              fontSize: '9px',
              lineHeight: '11px',
              fontWeight: '700',
              textAlign: 'left',
            },
          },
        }}>
          <Typography className="subtitle">{props.title}</Typography>
          <Button
            variant="filled" color="secondary" size="small"
            className="ScanQRCodeBtn"
            startIcon={<img src="/assets/icon-scan-qr.png" alt="scan QR code" />}
            onClick={handleScanBtn}
          >
            <span className='text'>{translations[language].scanQRCode}</span>
          </Button>
        </Box>
        <Input
          placeholder={props.inputPlaceHolder}
          disableUnderline={true}
          sx={{
            borderRadius: "15px",
            backgroundColor: "white",
            display: "flex", alignItem: "stretch",
            width: "100%",
            height: '49px',
            textAlign: 'right',
            px: '17px',

            '&.MuiInput-root': {

              border: '1px solid black',
              boxShadow: 'inset 0px 2px 0px rgba(0, 0, 0, 0.8)',
            },

            '.MuiInput-input::placeholder': {
              color: '#808080',
              ...fontHelpers(300, 13, 16.25),
            }

          }}
          value={props.address}
          onChange={props.onChange}
        />
      </Box>
      <QRCodeScanner
        open={showQRCodeScanner}
        onClose={() => { setShowQRCodeScanner(false) }}
        onData={(data) => { props.onScan(data); setShowQRCodeScanner(false) }}
        onError={(err) => { setShowQRCodeScanner(false) }}
      />
    </>
  )
}