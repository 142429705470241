import React, { useState, useContext, useEffect } from 'react';
import Box from "@mui/material/Box";
import './Navbar.css';
import './Login.css'
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import md5 from 'md5';
import { UserContext } from "../App"
import { InputLabel, OutlinedInput, Divider, Grid } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../Themes'

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'


const styles = {
    formInputValidStyle: {
        // borderBottom: '2px solid #A1A8B2',
    },

    formInputInvalidStyle: {
        // borderBottom: '2px solid red',
    }
};

function Login() {

    const { dispatch } = useContext(UserContext);

    const history = useHistory();
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    // const [googleAuth, setGoogleAuth] = useState("");
    const [Verification, setVerification] = useState("");
    const [Validate, setValidate] = useState(true);
    const [ReponseBoolen, setReponseBoolen] = useState(false);
    const [ReponseString, setReponseString] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const preloadImages = ((images) => {
        images.forEach((url) => {
            const img = new Image();
            img.src = url;
        });
    })

    const assetsContext = require.context('../../public/assets', false, /\.(png|jpe?g|svg)$/);

    const imageList = assetsContext.keys().map((assetsContext) => {
        return '/assets/' + assetsContext
    });

    useEffect(() => {
        // Hiding the navbar as no user is logged in.
        dispatch({ type: "HIDE_NAVBAR" })
        setTimeout(() => {
            preloadImages(imageList);
        }, 3000);
    }, [dispatch]);

    const { language, switchLanguage } = useLanguage();

    const Focus = () => {
        setValidate(true);
        setReponseBoolen(false);
    }

    const IsAuth = () => {
        const Auth = localStorage.getItem("AuthToken");
        if (Auth === "" || Auth === null) {
            return;
        }
        else {
            const options = {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-auth-token': Auth,
                }
            };

            // Making Request Here
            fetch(process.env.REACT_APP_BASE_URL + "/core/account/getUser", options)
                .then(response => response.json()).then(data => {
                    if (data.code === 0) {
                        history.push('/wallet');
                    } else {
                        window.localStorage.removeItem("AuthToken");
                        window.localStorage.removeItem("DisplayCurrency")
                    }
                }).catch(() => {
                })
        }
    }


    const SetStatesToInitial = () => {
        setEmail("");
        setPassword("");
        setVerification("");
    }


    const Login = (e) => {
        e.preventDefault();
        setIsLoading(true)
        if (!Email || !Password) {
            setValidate(false);
            setTimeout(() => {
                setValidate(true);
            }, 5000);
            return;
        }

        const options = {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "timestamp": "2022-04-29T07:09:42.954Z",
                "password": md5(Password.trim().toLowerCase()),
                "email": Email,
                // "authenticatorToken": googleAuth,
                "signature": md5("abcs1234"),
                "authenticatorToken": Verification
            })
        };

        // Making Request Here
        fetch(process.env.REACT_APP_BASE_URL + "/core/account/login", options)
            .then(response => response.json()).then(data => {
                console.log(data)
                if (data && Number.isInteger(data.code)) {
                    if (data.code === 0) {
                        setIsLoading(false)
                        window.localStorage.setItem("AuthToken", data.results[0].token);
                        dispatch({ type: "LOGIN", payload: true });
                        SetStatesToInitial();
                        history.push('/wallet');
                        return
                    } else if (data.code !== 0) {
                        setIsLoading(false)
                        setReponseBoolen(true);
                        setReponseString(data.message);
                        window.localStorage.removeItem("AuthToken");
                        return
                    }
                } else {
                    setIsLoading(false)
                    setReponseBoolen(true);
                    setReponseString('Something Wrong. Please contact Administrator');
                }
            }).catch(err => {
                setIsLoading(false)
                console.log(err);
                setReponseBoolen(true);
                setReponseString('Connection Lost. Login Timeout');
            })

        setTimeout(() => {
            setReponseBoolen(false);
        }, 5000)
    };

    const useStyles = makeStyles((theme) => ({
        languageButton: {
            borderRadius: '20px',
            padding: '8px 16px',
            fontSize: '14px',
            fontWeight: '600',
            transition: 'all 0.3s',
            '&:active': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.text.secondary,
            },
        },
        buttonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px' // Adds some spacing before other content, you can adjust as needed
        },
        innerContainer: {
            width: '20%'
        },
    }));

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme.login}>
            <Box onLoad={IsAuth} component={'div'} className="wallet-app-page"
                sx={{
                    display: {
                        md: 'flex',
                        xs: 'flex'
                    },
                    alignItems: 'center',
                    flexDirection: 'column',
                    pb: "150px",
                    maxWidth: '600px',
                    margin: 'auto'
                }}
            >
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between', // this will put space between the two items
                        alignItems: 'center',
                        mb: "68px",
                    }}
                >
                    <Typography variant="h6" component="div"
                        sx={{
                            width: "50%",
                            fontSize: '24px',
                            fontWeight: '700',
                            color: '#000',
                        }}
                    >
                        {translations[language].welcome}
                    </Typography>

                    <div className={classes.buttonContainer}>
                        <div className={classes.innerContainer}>
                            <Button
                                className={classes.languageButton}
                                sx={{
                                    backgroundColor: theme.login.palette.primary.light,
                                    color: theme.login.palette.button.text,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    '&:focus': {
                                        backgroundColor: theme.login.palette.primary.light,
                                        color: theme.login.palette.button.text,
                                    },
                                }}
                                onClick={() => switchLanguage(language === 'en' ? 'zh' : 'en')}
                            >
                                {language === 'en' ? '中文' : 'English'}
                            </Button>
                        </div>
                    </div>
                </Box>
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: "70px"
                    }}
                >
                    <img style={{ width: '220px', }} src="assets/logo.svg" alt="logo" />
                </Box>
                <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', }} >
                    <Box
                        id="sign-in-form"
                        component="form"
                        method='POST'
                        noValidate
                        autoComplete="off"
                        sx={{ width: '100%', color: '#FFFFFF', fontSize: '20px' }}
                        onSubmit={Login}
                    >
                        <FormControl>
                            <InputLabel shrink htmlFor="sign-in-email">
                                {translations[language].email}
                            </InputLabel>
                            <OutlinedInput
                                id="sign-in-email"
                                name="eamil"
                                placeholder='Enter your Email'
                                autoComplete='off'
                                noValidate
                                type="text"
                                sx={Validate ? styles.formInputValidStyle : styles.formInputInvalidStyle}
                                onFocus={Focus}
                                value={Email}
                                onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                                autoFocus
                            />
                        </FormControl>

                        <FormControl>
                            <InputLabel shrink htmlFor="sign-in-password">
                                {translations[language].password}
                            </InputLabel>
                            <OutlinedInput
                                id="sign-in-passsword"
                                name="password"
                                placeholder='Enter your Password'
                                autoComplete='off'
                                noValidate
                                type="password"
                                sx={Validate ? styles.formInputValidStyle : styles.formInputInvalidStyle}
                                onFocus={Focus}
                                value={Password}
                                onChange={(e) => setPassword(e.target.value.trim().toLowerCase())}
                            />
                        </FormControl>

                        <Typography variant="h6" sx={Validate ? { display: 'none' } : { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' }} component="div">
                            <ErrorOutlineIcon sx={{ mr: 1 }} />Fill all the fields!
                        </Typography>

                        <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                            <ErrorOutlineIcon sx={{ mr: 1 }} />{ReponseString}
                        </Typography>

                        <Button
                            id="submit"
                            onClick={Login}
                            variant="contained"
                            disabled={isLoading}
                            sx={{
                                backgroundColor: theme.login.palette.primary.main,
                                mt: "48px",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {translations[language].login}
                        </Button>


                        <Divider variant="middle" sx={{ mt: "50px" }} />
                        <Grid container id="bottom-actions" sx={{ textAlign: "center", }}>
                            <Grid item xs={6}>
                                <Link className='link' to={'/createAccount'} >
                                    {translations[language].createAccount}
                                </Link>
                            </Grid>

                            <Grid item xs={6}>
                                <Link className='link' to={'/ForgotPassword'} >
                                    {translations[language].forgotPassword}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Login;