import React, { useState, useEffect, useContext } from 'react';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  CircularProgress,
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';

import { useHistory } from 'react-router-dom';

import { UserContext } from "../App"

import { SlideInDialog, ApiProgressDialog } from './Dialogs'
import { WalletBox, SourceTokenPicker, WhiteDivider } from './Shared'
import { isAuth, toWei, getCoinInfo, GetWalletAssetsWrapped } from '../functions/functions'
import { GetPlans, GetUserStakeHistory, SetUserPlan, } from '../functions/network';
import { Grid, styled } from '@mui/material';
import moment from 'moment';

export const Title = styled(Typography)((theme) => ({
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  color: 'white',
  marginBottom: '5px',
}));

export const Subtitle = styled(Typography)((theme) => ({
  fontSize: '13px',
  lineHeight: '16px',
  fontWeight: '500',
  fontStyle: 'normal',
  color: 'white',
}));

export function TitleContainer(props) {
  return (
    <div style={{
      display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start',
      marginBottom: '13px'
      }}
    >
      {props.children}
    </div>
  );
}

export function GuaranteedReturnDialog(props) {
  const { state, dispatch } = useContext(UserContext);
  const history = useHistory();
  const [selectedPlan, setSelectedPlan] = useState(0);

  // Keep track whether we get the asset or not.
  const [getWalletDone, SetGetWalletDone] = useState(false);
  const [getPlansDone, setGetPlansDone] = useState(false);
  const [plans, setPlans] = useState(null);
  const [plansToShow, setPlansToShow] = useState([]);

  const [selectedCoin, setSelectedCoin] = useState('eth');
  const [amount, setAmount] = useState('0');

  const GetPlansWrapped = async () => {
    setGetPlansDone(false);
    const plans = await GetPlans();
    setGetPlansDone(true);
    setPlans(plans);
    return plans;
  }

  useEffect(() => {
    setSelectedCoin(props.coinType);
  }, [props.coinType]);

  useEffect(() => {
    isAuth(history).then((user) => {
      return Promise.all([
        GetPlansWrapped(), GetWalletAssetsWrapped(dispatch)
      ])
    }).then(([plans, wallet]) => {
      SetGetWalletDone(true);
    });
  }, [history, ]);

  useEffect(() => {
    if (!plans) {
      setPlansToShow([]);
    } else {
      setPlansToShow(plans.map((plan, i) => {
        if (!props.coinType || (plan.ccy !== props.coinType.toUpperCase())) {
          return null;
        }

        return {
          ...plan,
          i,
        }
      }).filter(planOrNull => planOrNull !== null));
    }
  }, [props.coinType, plans])

  const handleDialogOnEnter = () => {
    // console.log("on enter. fetch the Key");
  };

  const handleDialogOnExited = () => {
    // console.log("onExited");
    // clean up
  };

  const handleClickPlan = (i) => {
    return function(e) {
      console.log('selected plan', plans[i]);
      setSelectedPlan(i);
    }
  }

  const plansStyles = {
    mb: '14px',

    '.plan': {
      background: 'white',
      borderRadius: '15px',
      paddingTop: '9px',
      paddingBottom: '9px',
      border: '3px solid transparent',

      '&.selected': {
        border: '3px solid #00BCD4',
      },

      '.days': {
        color: '#004AAD',
        fontSize: '12px',
        lineHeight: '30px',
        fontWeight: 700,

        'span': {
          fontSize: '24px',
          lineHeight: '30px',
        }
      },

      '.rate': {
        color: 'black',
        fontSize: '13px',
        lineHeight: '16px',
        fontWeight: 700,

        'span': {
          color: '#00BCD4',
        }
      }
    }
  }

  let available = '0';
  switch (selectedCoin){
    case 'eth':
      available = state.ethAvailable;
      break;
    case 'usdt':
      available = state.usdtAvailable;
      break;
    default:
      console.error("Unknown coin", selectedCoin);
  }

  let pickerProps = {
    tokenType: selectedCoin,
    ...getCoinInfo(selectedCoin),
    available: available,
    availableCurrencies: () => [],  // Return empty array to prevent user to pick.
    // onClickTokenPicker: (e) => {
    //   // TODO: 'Show the TokenPickerDialog. see Wallet.jsx:1067'
    //   // AS of 2022-09-06, we dont have other plan that use other than ETH.
    //   console.log();
    // },
    amount: amount,
    onChangeAmount: (e) => {
      setAmount(e.target.value);
    },
    inputProps: {
      sx: {
        mb: '15px',
      }
    },
    availableText: "Available amount",
  }

  const handleConfirmButton = (e) => {
    // TODO: check plan.ccy and picker
    props.onConfirm({
      plan: plans[selectedPlan],
      coinType: selectedCoin,
      amount: amount,
    });
  };

  let plansComponents = plansToShow.map((plan) => {
    return (
      <Grid key={plan.i} item xs={6}>
        <Box className={plan.i === selectedPlan ? "plan selected" : "plan"} onClick={handleClickPlan(plan.i)}>
          <Typography className='days' align='center'><span>{plan.days}</span> Days</Typography>
          <Typography className='rate' align='center'>APR = <span>{(100 * plan.estEarnings).toFixed(2)}%</span></Typography>
        </Box>
      </Grid>
    )
  })

  return (
    <SlideInDialog
      open={props.open}
      onClose={props.onClose}
      title={props.coinType ? `Stake ${getCoinInfo(props.coinType).coinName}` : "Stake"}
      TransitionProps={{
        onEnter: handleDialogOnEnter,
        onExited: handleDialogOnExited,
      }}
    >
      <WalletBox variant="contained" color="primary">
        <TitleContainer>
          <div>
            <Title>Guaranteed Return Plan</Title>
            <Subtitle sx={{color: '#00BCD4'}}>Earn safely</Subtitle>
          </div>
          <img src="/assets/guaranteed_return_plan.png" alt="Guaranteed Return Plan logo" />
        </TitleContainer>

        <WhiteDivider variant='fullWidth' />

        {plans === null &&
          <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
          <Typography><CircularProgress /></Typography>
          <Typography>Fetching plans</Typography>
        </Box>
        }
        {plans &&
        <Box>
          {plansToShow.length > 0 &&
            <>
              <Typography align="center" sx={{fontWeight: 500, fontSize: '16px', lineHeight: '20px', mb: '14px'}}>Choose a plan</Typography>
              <Grid container sx={plansStyles} spacing={2}>
                {plansComponents}
              </Grid>
              <Typography align="center" sx={{fontWeight: 500, fontSize: '11px', lineHeight: '14px'}}>APR = Annual Percentage Rate</Typography>
              <WhiteDivider variant='fullWidth' />

              <SourceTokenPicker {...pickerProps} />

              <Box>
                <Button variant='contained' color='secondary'
                sx={{width: '126px', marginX: 'auto', display: 'block'}}
                onClick={handleConfirmButton}
                >Confirm</Button>
              </Box>
            </>
          }
          {plansToShow.length == 0 &&
            <>
              <Typography align="center" sx={{fontWeight: 500, fontSize: '16px', lineHeight: '20px', mb: '14px'}}>We don't have plan for {getCoinInfo(props.coinType).coinName} yet. Stay tuned!</Typography>
            </>
          }
        </Box>
        }
      </WalletBox>
    </SlideInDialog>
  );
}


export function GuaranteedReturnConfirmDialog(props) {
  const [shouldCallApi, setShouldCallApi] = useState(false);
  const [apiState, setApiState] = useState("loading");
  const [serverErrorMessage, setServerErrorMessage] = useState("")
  const handleConfirm = (e) => {
    setApiState("loading");
    setShouldCallApi(true);
    SetUserPlan({
      "plan": props.plan.plan,
      "ccy": props.plan.ccy,
      "duration": props.plan.days,
      "quantity": toWei(props.amount, props.plan.ccy)
    }).then(() => {
      setApiState("success");
    }).catch((err) => {
      console.error(err);
      setApiState("failure");
      setServerErrorMessage(err.data.message || "Sorry! We cannot process your request at this moment. Please try again later.")
    })
  }

  const apiProgressDialogProps = {
    open: shouldCallApi,
    status: apiState,
    onClose: () => {
      setShouldCallApi(false);
      apiState === 'success' ? props.onConfirm() : props.onClose();
      setApiState("init");
    },
    message: serverErrorMessage,
    successMessage: (
      <Box>
        <img src='/assets/tick.png' alt="tick" style={{
          display: 'block',
          margin: '0 auto',
          width: '70px',
          marginBottom: '38px',
        }}/>
        <Typography align='center'
          sx={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '25px',
          }}
        >Plan Settled Successfully</Typography>
      </Box>
    ),
  };

  return (
    <>
      <SlideInDialog
        open={props.open}
        onClose={props.onClose}
        title="Agreement"
      >
        <WalletBox variant="contained" color="primary">
          <Box sx={{mb: '15px'}}>
            <Typography>Agreement</Typography>
            <Typography>By clicking “Yes, I Confirm”, you have read and agree the following points of agreement:</Typography>
            <Typography>1. wallet launched this plan for wallet users to gain proceeds through idle cryptocurrency assets.</Typography>
            <Typography>2. wallet Savings assets will be used in cryptocurrency leveraged borrowing and other businesses.</Typography>
            <Typography>3. When you engage in this plan, you will unconditionally authorize wallet to distribute the leveraged interest according to the rules of the platform.</Typography>
            <Typography>4. You shall abide by the relevant laws of the State to ensure that the sources of assets are legitimate and compliant when using wallet service.</Typography>
            <Typography>5. When you engage in this plan, you should fully recognize the risks of investment in cryptocurrency and operate cautiously.</Typography>
            <Typography>6. You agree that all investment operations conducted on wallet represent your true investment intentions and that unconditionally accept the potential risks and benefits of your investment decisions.</Typography>
            <Typography>7. wallet reserves the right to suspend or terminate wallet guaranteed return plan. If necessary, wallet can suspend and terminate the plan at any time.</Typography>
            <Typography>8. Due to network delay, computer system failures and other force majeure, which may lead to delay, suspension or deviation of wallet guaranteed return plan execution, wallet will use commercially reasonable effort to ensure but not promise that wallet guaranteed return plan execution system run stably and effectively. wallet  does not take any responsibility if the final execution doesn’t match your expectations due to the above factors.</Typography>
            <Typography>9. You are aware of the risks and confirm to engage in this guaranteed return plan.</Typography>
          </Box>
          <Button variant="contained" color="secondary"
            sx={{width: "155px", mx:"auto", display: "block"}}
            onClick={handleConfirm}
          >Yes, I Confirm</Button>
        </WalletBox>
      </SlideInDialog>
      <ApiProgressDialog
        {...apiProgressDialogProps}
      />
    </>
  )
}

function getRemaining({time, duration}, now) {
  const end = moment(time).add(duration, "days");
  const diff = end.diff(now); // same as end - now
  const isEnded = diff <= 0; // end time is earlier than now.
  if (isEnded) {
    return "Ended";
  }

  const days = diff / (1000 * 60 * 60 * 24); // 1 day in milliseconds;
  if (days > 1) return `in ${days.toFixed(0)} days`;

  const hours = days * 24; // in hour
  if (hours > 1) return `in ${hours.toFixed(0)} hours`;

  const mins = hours * 60;
  return `in ${mins.toFixed(0)} mins`;
}

export function MyPlansDialog(props) {
  const [plans, setPlans] = useState([]);

  const handleDialogOnExited = () => {
    setPlans([]);
  };

  const handleDialogOnEnter = () => {
    GetUserStakeHistory()
      .then((data) => {
        setPlans(data);
      })
  }

  const now = moment.utc();

  return (
    <SlideInDialog
      open={props.open}
      onClose={props.onClose}
      title="My Plans"
      TransitionProps={{
        onEnter: handleDialogOnEnter,
        onExited: handleDialogOnExited,
      }}
    >
      {/* <Typography>Transcation Log</Typography> */}
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow sx={{"> .MuiTableCell-root": {padding: "0"}}}>
              <TableCell align='center'>Start Time</TableCell>
              <TableCell align='center'>Currency</TableCell>
              <TableCell align='center'>Type</TableCell>
              <TableCell align='center'>Amount</TableCell>
              <TableCell align='center'>Remaining / Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              'tr.tx-record-row:nth-of-type(odd)': {
                backgroundColor: '#F7F8FA',
              },
            }}
          >
            {plans.length === 0 &&
              <TableRow>
                <TableCell colSpan={5}>No plan found.</TableCell>
              </TableRow>
            }
            {plans.length > 0 && plans.map((tx, i) => (
              <TableRow key={tx.txnHash || i} className="tx-record-row">
                <TableCell>{tx.time.format('DD-MMM-YYYY HH:mm')}</TableCell>
                <TableCell>{tx.currency}</TableCell>
                <TableCell>{tx.type}</TableCell>
                <TableCell align="right">{tx.amount}</TableCell>
                <TableCell sx={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}>{getRemaining(tx, now)} / {tx.duration} days</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SlideInDialog>
  )
}