import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { UserContext } from "../App"
import { useHistory } from 'react-router-dom';
import {
    ShoppingCart,
    ModeEdit
} from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
    Modal,
    Box,
    Button,
    IconButton,
    Snackbar,
    Stepper, Step, StepLabel, MobileStepper, Paper
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import theme from '../Themes'

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

import { PageHeader } from './Shared.jsx';
import { isAuth } from '../functions/functions.js'

function Store() {
    const history = useHistory();

    const { dispatch } = useContext(UserContext);
    const { language, switchLanguage } = useLanguage();
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    // State for controlling the visibility of the modal
    const [openModal, setOpenModal] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [cartItemCount, setCartItemCount] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [orders, setOrders] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const ITEMS_PER_PAGE = 6; // Set the number of items you want per page
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [end, setIsEnd] = useState(false);
    const loader = useRef(null);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    useEffect(() => {
        dispatch({ type: "LOGIN", payload: true });

        dispatch({ type: "SHOW_NAVBAR" });

        try {
            let cartProducts = JSON.parse(localStorage.getItem("Cart"))
            if (cartProducts && cartProducts.products && cartProducts.products.length)
                setCartItemCount(cartProducts.products.length)
        } catch { }

        isAuth(history).then((user) => {
            setUserInfo(user)
            console.log(userInfo)
        })
            .catch((e) => {
                //window.localStorage.removeItem("AuthToken");
                //window.localStorage.removeItem("DisplayCurrency");
                //history.push('/');
            })
    }, [dispatch])

    const handleObserver = useCallback(
        (entries) => {
            const target = entries[0];
            if (target.isIntersecting && !loading) {
                fetchProducts(page);
            }
        },
        [loading, page] // Ensure the effect depends on the loading and page state
    );

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });

        if (loader.current) observer.observe(loader.current);

        return () => observer.disconnect(); // Clean up the observer properly
    }, [handleObserver]);

    const fetchProducts = useCallback(async (pageNum) => {
        if (loading || end ) return; // Exit early if already loading

        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/core/store/list?page=${pageNum}&limit=${ITEMS_PER_PAGE}`
            );
            const data = await response.json();
            if (data.products.length) {
                setProducts((prevProducts) => [...prevProducts, ...data.products]);
            } else if (data.products.length < 6) {
                // If no data is returned, we can assume there are no more pages to fetch.
                // You could set a state here to indicate that you've reached the end.
                setIsEnd(true)
            }
        } catch (err) {
            console.error('Error fetching products:', err);
        } finally {
            setLoading(false);
            setPage((ref) => ref + 1)
        }
    }, [loading]);

    const goToCart = () => {
        history.push('/viewCart'); // This function uses history.push to navigate
    };

    const goToOrder = () => {
        history.push('/orders'); // This function uses history.push to navigate
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
        setQuantity(1); // Reset quantity
    };

    const handleOpenModal = async (product) => {
        setSelectedProduct(product);
        setOpenModal(true); // This should open the modal
    };

    const handleAddCart = async (id, quantity) => {
        let countItems = 0
        try {
            let cartProducts = JSON.parse(localStorage.getItem("Cart"))
            //check for cartProducts contains order
            if (cartProducts && cartProducts.products) {
                let idExist = false
                cartProducts.products = cartProducts.products.map((product) => {
                    if (!product.id || !products.map((getProduct) => {return {id: getProduct.id}}).includes(product.id)) {
                        return null
                    }
                    countItems++
                    if (product.id === id) {
                        product.quantity += quantity
                        idExist = true
                    }
                    return product
                }).filter(product => product != null)
                if (!idExist) {
                    cartProducts.products.push({ id, quantity })
                }

                localStorage.setItem("Cart", JSON.stringify(cartProducts))
            } else {
                localStorage.setItem("Cart", JSON.stringify({ products: [{ id, quantity }] }))
                countItems++
            }
        } catch {
            localStorage.setItem("Cart", JSON.stringify({ products: [{ id, quantity }] }))
            countItems++
        }
        setCartItemCount(countItems)
        setOpenSnackbar(true);
        handleCloseModal();
        setTimeout(() => {
            setOpenSnackbar(false); // Hide the Snackbar
        }, 1000);
    };

    const handleBuyNow = async (id, quantity) => {
        history.push('/viewCart', { products: [{ id, quantity }] });
    };

    const incrementQuantity = () => {
        setQuantity((prevQuantity) => prevQuantity + 1);
    };

    const decrementQuantity = () => {
        setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    };

    return (
        <ThemeProvider theme={theme.wallet}>
            <Box className="wallet-app-page"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',

                    maxWidth: '600px', margin: 'auto',

                    backgroundColor: "#F4F4F4",
                    width: "100%",
                    minHeight: "100vh",
                    paddingTop: "72px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingBottom: "150px",
                }}
            >
                <PageHeader auth={false} title={translations[language].store} showLogout={true} sx={{ width: "100%" }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "100%", padding: "16px" }}>
                    {userInfo?.accountType === "Business" && (<IconButton onClick={null}>
                        <ModeEdit style={{ fontSize: '3rem' }} color={'primary'} onClick={() => history.push('/storeCMS')} />
                    </IconButton>)}
                    <IconButton onClick={goToCart}>
                        <Badge badgeContent={cartItemCount} color="secondary">
                            <ShoppingCart style={{ fontSize: '3rem' }} color={'primary'} />
                        </Badge>
                    </IconButton>
                    {/* Order Tracking Icon */}
                    <IconButton onClick={goToOrder}>
                        <Badge badgeContent={orders.length} color="secondary">
                            <ReceiptIcon style={{ fontSize: '3rem' }} color={'primary'} />
                        </Badge>
                    </IconButton>
                </Box>
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        mb: "68px",
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {products.map((product) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={product.id} sx={{ minWidth: 284 }}>
                                    <Card sx={{ height: 350 }}>
                                        <CardActionArea onClick={() => handleOpenModal(product)}>
                                            <CardMedia
                                                component="img"
                                                height="260"
                                                width="260"
                                                image={product?.images && product.images[0]?.base64}
                                                alt={product.name[language]}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h8" component="div">
                                                    {product.name[language]}
                                                </Typography>
                                                {product.originalPrice > 0 ? (
                                                    // Show the original price crossed out and the discounted price
                                                    <>
                                                        <Typography variant="body2" style={{ textDecoration: 'line-through' }}>
                                                            {product.originalPrice} {product.currency.toUpperCase()}
                                                        </Typography>
                                                        <Typography variant="body1" color="primary">
                                                            {product.price} {product.currency.toUpperCase()}
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    // If there is no discount, just show the price
                                                    <Typography variant="body1">
                                                        {product.price} {product.currency.toUpperCase()}
                                                    </Typography>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                            <div>
                                {/* Render products */}
                                <div ref={loader} />
                            </div>
                        </Grid>
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={3000}
                            onClose={() => setOpenSnackbar(false)}
                            message="Item added to cart"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        />
                        {selectedProduct && (
                            <Modal
                                open={Boolean(selectedProduct)}
                                onClose={() => setSelectedProduct(null)}
                                aria-labelledby="product-detail-title"
                                aria-describedby="product-detail-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                        outline: 'none' // removes the default focus outline
                                    }}
                                >
                                    <Card>
                                        <CardActionArea>
                                            <SwipeableViews
                                                axis={'x'}
                                                index={activeStep}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                {selectedProduct.images
                                                    .sort((a, b) => a.order - b.order)
                                                    .map((image, index) => (
                                                        <CardMedia
                                                            key={image.order}
                                                            component="img"
                                                            height="260"
                                                            image={image.base64}
                                                            alt={`image-${index}`}
                                                        />
                                                    ))}
                                            </SwipeableViews>
                                            <MobileStepper
                                                steps={selectedProduct.images.length}
                                                position="static"
                                                activeStep={activeStep}
                                                nextButton={
                                                    <Button
                                                        size="small"
                                                        onClick={handleNext}
                                                        disabled={activeStep === selectedProduct.images.length - 1}
                                                    >
                                                        Next
                                                        <KeyboardArrowRight />
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button
                                                        size="small"
                                                        onClick={handleBack}
                                                        disabled={activeStep === 0}
                                                    >
                                                        <KeyboardArrowLeft />
                                                        Back
                                                    </Button>
                                                }
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {selectedProduct.name[language]}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {selectedProduct.description[language]}
                                                </Typography>
                                                <Typography variant="body1">
                                                    Price: {selectedProduct.price} {selectedProduct.currency.toUpperCase()}
                                                </Typography>
                                                <IconButton onClick={decrementQuantity} aria-label="reduce quantity">
                                                    <RemoveIcon />
                                                </IconButton>
                                                <Typography component="span">{quantity}</Typography>
                                                <IconButton onClick={incrementQuantity} aria-label="increase quantity">
                                                    <AddIcon />
                                                </IconButton>
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleAddCart(selectedProduct.id, quantity)}
                                                    >
                                                        Add to Cart
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleBuyNow(selectedProduct.id, quantity)}
                                                    >
                                                        Buy Now
                                                    </Button>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleCloseModal}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Modal>
                        )}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Store;
