import React, { createContext, useReducer, useState, useContext } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import CreateAccount from "./components/CreateAccount";
import Product from "./components/Product";
import AboutUs from "./components/AboutUs";
import Wallet from "./components/Wallet";
import Markets from "./components/Markets";
import Store from "./components/Store";
import Orders from "./components/Orders";
import StoreCMS from "./components/StoreCMS";
import ViewCart from "./components/ViewCart";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import { reducer, initialState } from "./reducer/useReducer";
import themes from "./Themes";
import { ThemeProvider } from '@mui/material/styles';
import ChangePassword from "./components/ChangePassword";

// context-api
export const UserContext = createContext();

export const LanguageContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [language, setLanguage] = useState(localStorage.getItem("Language") || 'en');

  const switchLanguage = (lang) => {
    setLanguage(lang);    
    window.localStorage.setItem("Language", lang);
  };

  return (
    <>
      <ThemeProvider theme={themes.global}>
        <UserContext.Provider value={{ state, dispatch }}>
          <LanguageContext.Provider value={{ language, switchLanguage }}>
            <Navbar />
            <Switch>
              <Route path="/" component={Login} exact />
              <Route path="/ForgotPassword" component={ForgotPassword} exact />
              <Route path="/product" component={Product} exact />
              <Route path="/createAccount" component={CreateAccount} exact />
              <Route path="/changePassword" component={ChangePassword} exact />
              <Route path="/aboutUs" component={AboutUs} exact />
              <Route path="/wallet" component={Wallet} exact />
              <Route path="/markets" component={Markets} exact />
              <Route path="/store" component={Store} exact />
              <Route path="/orders" component={Orders} exact />
              <Route path="/storeCMS" component={StoreCMS} exact />
              <Route path="/viewCart" component={ViewCart} exact />
              <Route path="/success" component={Success} exact />
              <Route path="/cancel" component={Cancel} exact />
            </Switch>
          </LanguageContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
export const useLanguage = () => {
  return useContext(LanguageContext);
};
