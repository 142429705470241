import React, { useEffect, useContext, useState } from 'react';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { UserContext } from "../App"

import { walletAppPage, PageHeader, WalletBox } from './Shared'
import { GuaranteedReturnDialog, GuaranteedReturnConfirmDialog, MyPlansDialog, Title, Subtitle, TitleContainer } from './GuaranteedReturn'
import { styled } from '@mui/material';

const WhiteBox = styled(Box)((theme) => ({
  backgroundColor: "white",
  marginLeft: '-24px',
  marginRight: '-24px',
  marginBottom: '-18px',
  borderRadius: '0px 0px 15px 15px',
  padding: '12px 24px 8px 24px',
}));

const ActionButton = styled(Button)((theme) => ({
  background: '#004AAD',
  borderRadius: '15px',
  color: 'white !important',
  padding: '9px 20px',
}));

function BlueBox(props) {
  let sx = {
    ...(props.sx ? props.sx : {}),
    width: '100%',
  }
  return (
    <WalletBox variant="contained" color="primary" sx={sx}>
      {props.children}
    </WalletBox>
  )
}

function Product() {
  const { dispatch } = useContext(UserContext);

  const [shouldOpenReturnDialog, setShouldOpenReturnDialog] = useState(false);
  const [shouldOpenReturnConfirmDialog, setShouldOpenReturnConfirmDialog] = useState(false);
  const [shouldOpenMyPlans, setShouldOpenMyPlans] = useState(false);

  const [plan, setPlan] = useState(null);
  const [coinType, setCoinType] = useState("eth");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    dispatch({type: "SHOW_NAVBAR"});
  }, [dispatch]);

  const handleGoToGuaranteedReturn = (coinType) => {
    return () => {
      setCoinType(coinType);
      setShouldOpenReturnDialog(true);
    }
  };

  const handlePlanSelected = ({plan, coinType, amount}) => {
    setPlan(plan);
    setAmount(amount);
    setShouldOpenReturnConfirmDialog(true);
  }

  const handleShowStakedPlans = () => {
    setShouldOpenMyPlans(true);
  }

  return (
    <walletAppPage>
      <PageHeader title="Product" showLogout={true} sx={{width: "100%"}} />

      <BlueBox sx={{mb: '20px',}}>
        <TitleContainer>
          <div>
            <Title>Guaranteed Return Plan</Title>
            <Subtitle sx={{color: '#00BCD4'}}>Earn safely</Subtitle>
          </div>
          <img src="/assets/guaranteed_return_plan.png" alt="Guaranteed Return Plan logo" />
        </TitleContainer>


        <WhiteBox sx={{mb: '0px', borderRadius: 0}}>
          <ActionButton
            sx={{
              background: '#004AAD',
              mr: '8px',
            }}
            onClick={handleGoToGuaranteedReturn("eth")}
          >
          Stake ETH
          </ActionButton>
          <ActionButton
            sx={{
              background: '#004AAD',
            }}
            onClick={handleGoToGuaranteedReturn("usdt")}
          >
          Stake USDT
          </ActionButton>
        </WhiteBox>
        <WhiteBox>
          <ActionButton
            sx={{
              background: '#00BCD4',
              mr: '8px',
            }}
            onClick={handleShowStakedPlans}
          >
          View my plans
          </ActionButton>
        </WhiteBox>
      </BlueBox>

      {/* <BlueBox>
        <TitleContainer>
          <div>
            <Title>Play a Earn</Title>
            <Subtitle sx={{color:'#FFD659'}}>Double Your Account At An Instant</Subtitle>
          </div>
          <img src="/assets/play_to_earn.png" alt="Play to earn logo" />
        </TitleContainer>

        <WhiteBox>
          <ActionButton disabled sx={{
              background: 'rgba(0, 0, 0, 0.4);',
            }}
          >Coming Soon</ActionButton>
        </WhiteBox>
      </BlueBox> */}

      <GuaranteedReturnDialog
        open={shouldOpenReturnDialog}
        coinType={coinType}
        onClose={()=>{setShouldOpenReturnDialog(false)}}
        onConfirm={handlePlanSelected}
      />

      <GuaranteedReturnConfirmDialog
        open={shouldOpenReturnConfirmDialog}
        onClose={()=>{
          setShouldOpenReturnConfirmDialog(false)}
        }
        onConfirm={() => {
          setShouldOpenReturnConfirmDialog(false)
          setShouldOpenReturnDialog(false)
        }}
        plan={plan}
        amount={amount}
      />

      <MyPlansDialog
        open={shouldOpenMyPlans}
        onClose={()=>{
          setShouldOpenMyPlans(false)}
        }
      />
    </walletAppPage>
  );
}

export default Product;
