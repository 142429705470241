import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import theme from '../Themes'
import { useLanguage } from '../App'

import translations from '../i18n/translation.json';
import { PageHeader } from './Shared.jsx';

const PaymentSuccess = () => {
    const location = useLocation();
    const history = useHistory();
    const { language, switchLanguage } = useLanguage();

    const handleBackToHome = () => {
        history.push('/'); // Redirects user to the home page
    };

    return (

        <ThemeProvider theme={theme.wallet}>
            <Box className="wallet-app-page"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',

                    maxWidth: '600px', margin: 'auto',

                    backgroundColor: "#F4F4F4",
                    width: "100%",
                    minHeight: "100vh",
                    paddingTop: "72px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingBottom: "150px",
                }}
            >
                <PageHeader auth={true} title={translations[language].about} showLogout={true} sx={{ width: "100%" }} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50vh',
                    }}
                >
                    <Typography variant="h3" gutterBottom>
                        Payment Successful!
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Thank you for your purchase.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBackToHome}
                        sx={{ mt: 3 }}
                    >
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PaymentSuccess;
