import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import './Navbar.css';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import md5 from 'md5';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { InputLabel, OutlinedInput, Divider, Grid, InputAdornment, Icon } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../Themes'

function ForgotPassword() {
    const [Validate, setValidate] = useState(true);
    const [Password, setPassword] = useState("");
    const [RePassword, setRePassword] = useState("");
    const [ReponseBoolen, setReponseBoolen] = useState(false);
    const [ReponseString, setReponseString] = useState('');
    const [passwordState] = useState('');
    const search = useLocation().search;

    let passwordEndAdornment;

    switch (passwordState) {
        case 'warning':
            passwordEndAdornment = <InputAdornment position="end">
                <Icon
                    edge="end"
                >
                    <img src="assets/warning-logo.svg" alt="warning logo" />
                </Icon>
            </InputAdornment>
            break
        case 'error':
            passwordEndAdornment = <InputAdornment position="end">
                <Icon
                    edge="end"
                >
                    <img src="assets/error-logo.svg" alt="error logo" />
                </Icon>
            </InputAdornment>
            break
        case 'valid':
            passwordEndAdornment = <InputAdornment position="end">
                <Icon
                    edge="end"
                >
                    <img src="assets/valid-logo.svg" alt="valid logo" />
                </Icon>
            </InputAdornment>
            break;
        default:
            passwordEndAdornment = null;
    }

    const Submit = (e) => {
        const resetToken = new URLSearchParams(search).get('resetToken');

        const options = {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                resetToken,
                "password": md5(Password.trim().toLowerCase())
            })
        };

        // Making Request Here
        fetch(process.env.REACT_APP_BASE_URL + "/core/account/resetPassword", options)
            .then(response => response.json()).then(data => {
                if (data.code === 0) {
                    setReponseBoolen(true);
                    setReponseString('Password updated successfully');
                }
                else {
                    setReponseBoolen(true);
                    setReponseString('Error, Please contact admin@boredtech.xyz');
                }
            }).catch(err => {
                console.log(err);
            })

    };

    return (
        <ThemeProvider theme={theme.login}>
            <Box component={'div'} className="wallet-app-page"
                sx={{
                    display: {
                        md: 'flex',
                        xs: 'flex'
                    },
                    alignItems: 'center',
                    flexDirection: 'column',
                    pb: "150px",
                    maxWidth: '600px',
                    margin: 'auto'
                }}
            >
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        mb: "68px",
                    }}
                >
                    <Typography variant="h6" component="div"
                        sx={{
                            fontSize: '24px',
                            fontWeight: '700',
                            color: '#000',
                        }}
                    >
                        Reset Password
                    </Typography>
                </Box>
                <Box component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: "70px"
                    }}
                >
                    <img style={{ width: '220px', }} src="assets/logo.svg" alt="logo" />
                </Box>
                <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', }} >
                    <Box
                        id="sign-in-form"
                        component="form"
                        method='POST'
                        noValidate
                        autoComplete="off"
                        sx={{ width: '100%', color: '#FFFFFF', fontSize: '20px' }}
                        onSubmit={Submit}
                    ><FormControl>
                            <InputLabel shrink>Password</InputLabel>
                            <OutlinedInput
                                id="sign-up-password"
                                name="password"
                                onFocus={() => setValidate(false)}
                                placeholder='Enter your Password'
                                value={Password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete='off' noValidate type="password"
                                endAdornment={passwordEndAdornment}
                                autoFocus
                            />
                        </FormControl>

                        <FormControl>
                            <InputLabel shrink>Re-enter Password</InputLabel>
                            <OutlinedInput
                                id="sign-up-re-password"
                                name="re-password"
                                onFocus={() => setValidate(false)}
                                placeholder='Re Enter Password'
                                value={RePassword}
                                onChange={(e) => setRePassword(e.target.value)}
                                autoComplete='off' noValidate type="password"
                            />
                        </FormControl>

                        {ReponseString === 'Password updated successfully' ? <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Bold', letterSpacing: '1px', fontSize: '14px', color: 'green', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                            <CheckCircleOutlineIcon sx={{ mr: 1 }} />{ReponseString}
                        </Typography> : <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                            <ErrorOutlineIcon sx={{ mr: 1 }} />{ReponseString}
                        </Typography>}
                        <Typography variant="h6" sx={Validate ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                            <ErrorOutlineIcon sx={{ mr: 1 }} />Fill all fields
                        </Typography>


                        <Button
                            id="submit"
                            onClick={Submit}
                            variant="contained"
                            sx={{
                                backgroundColor: theme.login.palette.primary.main, mt: "48px"
                            }}
                        >
                            Submit
                        </Button>

                        <Divider variant="middle" sx={{ mt: "50px" }} />
                        <Grid container id="bottom-actions" sx={{ textAlign: "center", }}>
                            <Grid item xs={6}>
                                <Link className='link' to={'/'} >
                                    Login
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link className='link' to={'/createAccount'} >
                                    Create Account
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    )
}

export default ForgotPassword;