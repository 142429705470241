import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Box, Button, Grid, Typography, Divider, Input } from "@mui/material";

import { useHistory } from 'react-router-dom';
import md5 from 'md5';
import { toWei, fromWei, toShortAddress, getCoinInfo, sleep } from '../functions/functions'
import useWindowDimensions from '../functions/useWindowDimensions'
import { isAuth, GetWalletAssetsWrapped } from '../functions/functions.js'
import { UserContext } from "../App"

import { ThemeProvider } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
import theme from "../Themes";
import "./Wallet.css";
import { ReactComponent as CopyIcon } from '../icons/icon-copy.svg';
import { ReactComponent as GearIcon } from '../icons/icon-gear.svg';
import { ReactComponent as ArrowIcon } from '../icons/icon-arrow.svg';
import { ReactComponent as AccountIcon } from '../icons/icon-account.svg';
import { ReactComponent as SecurityIcon } from '../icons/icon-shieldlock.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SlideInDialog, AccountDialog, SecurityDialog, VerificationDialog, SlideUpDialog, TokenPickerDialog, ApiProgressDialog, CoinTransactionHistoryDialog } from "./Dialogs"

import { CreateSwapOperation, GetSwapQuote, CheckSwapStatus } from '../functions/network';

import { PageHeader, WalletAppPage, getCoinIcon, CopyAddressButton } from './Shared'
import debounce from "lodash/debounce";

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'

function ManageAccountDialog(props) {
    const { language } = useLanguage();

    return (
        <SlideUpDialog
            open={props.open}
            onClose={props.onClose}
            title={translations[language].manageAccount}
            TitleSx={{ marginBottom: "40px", }}
            sx={{
                ".MuiButton-contained": {
                    width: "100%",
                    marginBottom: "24px",
                },
            }}
        >
            <Button variant='contained' onClick={props.onClickAccountBtn} startIcon={<AccountIcon />}>{translations[language].account}</Button>
            {/* <Button variant='contained' onClick={props.onClickVerificationBtn} startIcon={<VerificationIcon />}>Verification</Button> */}
            <Button variant='contained' onClick={props.onClickSecurityBtn} startIcon={<SecurityIcon />} disabled='True'>{translations[language].security}</Button>
        </SlideUpDialog>
    )
}

function SwapDialog(props) {
    const shortAddress = toShortAddress(props.address);
    let isDestinationTokenTypeSet = props.isDestinationTokenTypeSet();
    let swapSourceTokenIcon = getCoinIcon(props.sourceTokenType);
    let swapDestinationTokenIcon = isDestinationTokenTypeSet ? getCoinIcon(props.destinationTokenType) : null;
    let swapBtnAvailable = props.shouldTradeBtnAvailable();

    const [shouldShowApiProgress, setShouldShowApiProgress] = useState(false);
    const [progressStatus, setProgressStatus] = useState("init");
    const [progressMessage, setProgressMessage] = useState("");

    const handleSwap = async () => {
        //console.log("Call API to make the swap and poll the status");
        setProgressStatus("loading");
        setShouldShowApiProgress(true);
        let fromCcy, toCcy, amount;
        fromCcy = props.sourceTokenType.toUpperCase();
        toCcy = props.destinationTokenType.toUpperCase();
        amount = toWei(props.sourceTokenAmount, fromCcy);
        let body = {
            fromCcy,
            toCcy,
            amount,
        };
        //console.log(body);
        try {
            let ret = await CreateSwapOperation(body);
            //console.log(ret);
            if (ret.complete === 1) {
                setProgressStatus("success");
            } else {
                // We go to the retry loop
                const sleepTime = 3 * 1000; // sleep 3 seconds;
                const maxTrials = 10;
                let isDone = false;
                for (let i = 0; i < maxTrials; i++) {
                    await sleep(sleepTime);
                    let retryRet = await CheckSwapStatus({
                        requestId: ret.requestId
                    });
                    if (retryRet.complete === 1) {
                        setProgressStatus("success");
                        isDone = true;
                        break;
                    }
                }

                if (!isDone) {
                    setProgressStatus("failure");
                    let timeSpent = sleepTime * maxTrials / 1000;
                    setProgressMessage(`Swap was created but it was not completed after ${timeSpent.toFixed(0)} seconds.`);
                }
            }
        } catch (err) {
            setProgressStatus("failure");
            setProgressMessage(err.data.message);
            console.error(err);
        }
    };

    const onClose = (isSwapSuccess) => {
        props.onClose(!!isSwapSuccess);
    }

    return (
        <SlideInDialog
            open={props.open}
            onClose={onClose}
            title="Swap"
            closeButtonText="Back"
        >
            <Box
                sx={{
                    backgroundImage: 'url("/assets/membership-card.png")',
                    width: "329px",
                    height: "205px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "20px",
                    padding: "10px",
                    ".MuiTypography-root": {
                        color: "white",
                    }
                }}
            >
                <Typography>{props.name}</Typography>
                <Typography>{shortAddress}</Typography>
                <Divider variant="middle" sx={{ borderColor: 'white', mx: '-10px' }} />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>Amount</Typography>
                    <Typography>{props.sourceAsset.available} {props.sourceAsset.coinUnit}</Typography>
                </Box>
                <Box sx={{
                    backgroundColor: "white", borderRadius: "15px", padding: "10px 20px",
                    display: "flex", alignItem: "stretch", width: "100%",
                }}
                >
                    <Button onClick={props.onClickSourceTokenPicker}
                        startIcon={swapSourceTokenIcon}
                        endIcon={<ExpandMoreIcon />}
                        sx={{
                            padding: "0",
                            minWidth: "0",
                            color: "black",
                            ".MuiButton-startIcon, .MuiButton-endIcon": {
                                margin: "0",
                            }
                        }}
                    ></Button>
                    <Input type='number'
                        value={props.sourceTokenAmount}
                        onChange={props.onChangeSourceTokenAmount}
                        sx={{
                            flex: "1", /* so it takes all the remaining space. */
                            ".MuiInput-input": {
                                textAlign: "right",
                            },
                        }}
                    ></Input>
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItem: "center", justifyContent: "center", marginBottom: "20px", }}>
                <ArrowIcon />
            </Box>
            <Box sx={{
                backgroundColor: "#E0E0E0", borderRadius: "15px", boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)",
                padding: "20px",
            }}
            >
                <Box sx={{
                    backgroundColor: "white", borderRadius: "15px", padding: "10px 20px",
                    display: "flex", alignItem: "stretch", width: "100%",
                    marginBottom: "10px",
                }}
                >
                    <Button onClick={props.onClickDestinationTokenPicker}
                        startIcon={swapDestinationTokenIcon}
                        endIcon={<ExpandMoreIcon />}
                        sx={{
                            padding: "0",
                            minWidth: "0",
                            color: "black",
                            ...(isDestinationTokenTypeSet ? {} : { width: "100%", justifyContent: "flex-end", }),
                            ".MuiButton-startIcon, .MuiButton-endIcon": {
                                margin: "0",
                            },
                        }}
                    >{!isDestinationTokenTypeSet ? "Select Token" : null}</Button>
                    {isDestinationTokenTypeSet ?
                        <Input type='number'
                            value={props.destinationTokenAmount}
                            onChange={props.onChangeDestinationTokenAmount}
                            sx={{
                                flex: "1", /* so it takes all the remaining space. */
                                ".MuiInput-input": {
                                    textAlign: "right",
                                },
                            }}
                        ></Input>
                        : null
                    }
                </Box>
                <Box sx={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                    marginBottom: "20px",
                    ".MuiTypography-root": {
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '11px',
                        lineHeight: '14px',
                    }
                }}
                >
                    <Typography>Slippage Tolerance</Typography>
                    <Typography>0.5%</Typography>
                </Box>
                <Button
                    variant="contained"
                    disabled={!swapBtnAvailable}
                    onClick={handleSwap}
                    sx={{
                        display: "block",
                        margin: "0 auto",
                        width: "140px",
                    }}
                >Trade</Button>
            </Box>
            <ApiProgressDialog open={shouldShowApiProgress}
                onClose={() => {
                    setShouldShowApiProgress(false);
                    // Close the outer dialog and back to the membership card
                    onClose(progressStatus === 'success');
                }}
                status={progressStatus}
                message={progressMessage}
            />
        </SlideInDialog>
    )
}

function MemberCard(props) {
    const { language, switchLanguage } = useLanguage();
    const btn = (
        <Button className='copy-button' endIcon={<CopyIcon />}
            sx={{ textTransform: "none" }}
        >{toShortAddress(props.address)}</Button>
    )
    return (
        <Box id="member-card">
            <Box className='address-container'>
                <Typography>
                    {translations[language].address}:
                </Typography>
                <CopyAddressButton button={btn} address={props.address} />
            </Box>
            <img className='wallet-name' src="/assets/wallet-logo-white.svg" alt="wallet-logo" />
            <Typography className='member-name'>{props.name ? props.name : <Box sx={{
                display: 'flex', width: '329px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress color="inherit" />
            </Box>}</Typography>
            <Typography className='member-type'>{props.accountType}</Typography>
            <img src="/assets/membership-card.png" alt="member-card-background" />
            <Box className="manage-account-button-container">
                <Button startIcon={<GearIcon />} onClick={props.onClickManageAccountBtn}>{translations[language].manageAccount}</Button>
            </Box>
        </Box>
    );
}

function ProfolioItem(props) {
    const { language, switchLanguage } = useLanguage();
    let icon = getCoinIcon(props.coinType);
    const rowStyles = {
        display: "flex",
        alignItems: "flexStart",
        justifyContent: "space-between",
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '13px',
        lineHeight: '16px',
    };

    return (
        <Grid item xs={12}
            sx={{
                mb: "18px",
            }}
        >
            <Button variant="contained" color="secondary"
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    transition: [
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    ].join(","),
                }}
                onClick={() => props.onClick(props.coinType)}
            >
                <Box sx={{ width: "20px" }}>{icon}</Box>
                <Box sx={{ width: "calc(100% - 20px)", paddingLeft: "8px" }}>
                    <Box
                        sx={{
                            ...rowStyles,
                            color: "black"
                        }}
                    >
                        <Typography>{props.coinName}</Typography>
                        <Typography>{props.balance !== null ? props.balance?.toLocaleString('en-US') : <Box sx={{ display: 'flex', height: '20px' }}>
                            <CircularProgress />
                        </Box>}</Typography>
                    </Box>
                    <Box
                        sx={{
                            ...rowStyles,
                            color: "#999999"
                        }}
                    >
                        <Typography>{translations[language].available}</Typography>
                        <Typography>{props.available?.toLocaleString('en-US')}</Typography>
                    </Box>
                </Box>
            </Button>
        </Grid>
    )
}

function Profolio(props) {
    const { language, switchLanguage } = useLanguage();

    const handleOnClick = (coinType) => {
        // console.log("get tx for coinType:", coinType);
        props.onClickItem(coinType);
    }

    let items = props.items.map((item, i) => {
        return <ProfolioItem key={i} {...item} onClick={handleOnClick}></ProfolioItem>
    });
    return (
        <>
            <Typography
                sx={{
                    alignSelf: "flex-start", marginBottom: "15px",
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '16px',
                    lineHeight: '20px',
                }}
            >
                {translations[language].totalAccountValue}
            </Typography>
            <Grid container>
                {items}
            </Grid>
        </>
    );
}

function Wallet() {
    const { state, dispatch } = useContext(UserContext);

    const history = useHistory();
    const [userInfo, setUserInfo] = useState('');
    const [AccountType, setAccountType] = useState('');
    const [getWalletDone, SetGetWalletDone] = useState(false);
    const [btoBalance, SetBtoBalance] = useState(null);
    const [btoAvailable, SetBtoAvailable] = useState(null);

    const [shouldShowTxRecordsDialog, setShouldShowTxRecordsDialog] = useState(false);
    const [txCoin, setTxCoin] = useState(null);
    const [txAddress, setTxAddress] = useState("");

    const { language, switchLanguage } = useLanguage();

    useEffect(() => {
        dispatch({ type: "SHOW_NAVBAR" });
    }, [dispatch]);

    useEffect(() => {
        GetWalletAssetsWrapped(dispatch).then((wallet) => {
            SetGetWalletDone(true);
            SetBtoBalance(wallet.btoBalance);
            SetBtoAvailable(wallet.btoAvailableBalance);
            state.walletAddress = wallet.address
        });
        isAuth(history).then((user) => {
            setUserInfo(user)
            switch (user.accountType) {
                case "Member":
                    setAccountType(translations[language].accountMember)
                    break
                case "Business":
                    setAccountType(translations[language].accountBusiness)
                    break
                case "Agent":
                    setAccountType(translations[language].accountAgent)
                    break
                case "Admin":
                    setAccountType(translations[language].accountAdmin)
                    break
                default:
                    setAccountType(null)
            }
        })
            .catch((e) => {
                window.localStorage.removeItem("AuthToken");
                window.localStorage.removeItem("DisplayCurrency");
                history.push('/');
            })
        dispatch({ type: "SHOW_NAVBAR" });

    }, [history,]);

    const [ShowSwapDialog, setShowSwapDialog] = React.useState(false);
    const handleSwapOpen = (e) => {
        setShowSwapDialog(true);
    };

    const handleSwapClose = (isSwapSuccess) => {
        setShowSwapDialog(false);
        // cleanup
        setSwapSourceTokenType("bto");
        setSwapSourceTokenAmount("0");
        setSwapDestinationTokenType("");
        setSwapDestinationTokenAmount("0");

        if (isSwapSuccess) {
            GetWalletAssetsWrapped(dispatch);
        }
    };

    const [SwapSourceTokenDialog, setSwapSourceTokenDialog] = React.useState(false);
    const [SwapSourceTokenType, setSwapSourceTokenType] = React.useState("bto");
    const [SwapSourceTokenAmount, setSwapSourceTokenAmount] = React.useState("0");

    const [SwapDestinationTokenDialog, setSwapDestinationTokenDialog] = React.useState(false);
    const [SwapDestinationTokenType, setSwapDestinationTokenType] = React.useState("");
    const [SwapDestinationTokenAmount, setSwapDestinationTokenAmount] = React.useState("0");
    const [SwapDirection, setSwapDirection] = useState("from");

    let minHight4Card = (useWindowDimensions().width - 32) * 0.6434782608695652
    if (minHight4Card < 200)
        minHight4Card = 200
    if (minHight4Card > 365)
        minHight4Card = 365

    const items = [];
    //console.log('state.btoBalance: ', state.btoBalance)
    items.push({
        ...(getCoinInfo("bto")),
        balance: btoBalance,
        available: btoBalance,
    });

    if (state.usdtBalance && state.usdtAvailable) {
        items.push({
            ...(getCoinInfo("usdt")),
            balance: state.usdtBalance,
            available: state.usdtAvailable,
        });
    }

    const isSourceTokenTypeSet = () => { return SwapSourceTokenType !== "" };
    const isSourceTokenAmountSet = () => { return SwapSourceTokenAmount !== "" };
    const isDestinationTokenTypeSet = () => { return SwapDestinationTokenType !== "" };
    const isDestinationTokenAmountSet = () => { return SwapDestinationTokenAmount !== "" };
    const shouldTradeBtnAvailable = () => {
        return (
            (isSourceTokenTypeSet() && isSourceTokenAmountSet()) &&
            (isDestinationTokenTypeSet() && isDestinationTokenAmountSet()) &&
            // All data are set.
            (SwapSourceTokenType !== SwapDestinationTokenType)
        );
    }
    let SwapSourceAsset = items.find((item) => {
        if (item.coinType === SwapSourceTokenType) {
            return item;
        }
        return null;
    })
    if (!SwapSourceAsset) {
        SwapSourceAsset = {
            coinType: "",
            coinUnit: "",
            available: "",
        }
    }

    const [shouldShowManageAccountDialog, setShouldShowManageAccountDialog] = useState(false);
    const [shouldShowAccountDialog, setShouldShowAccountDialog] = useState(false);
    const [shouldShowVerificationDialog, setShouldShowVerificationDialog] = useState(false);
    const [shouldShowSecurityDialog, setShouldShowSecurityDialog] = useState(false);
    // const [shouldShowSwapConfirmDialog, setShouldShowSwapConfirmDialog] = useState(false);
    const [triggeredBy, setTriggeredBy] = useState("");

    const handleAccountFormSubmitDone = (e) => {
        setShouldShowAccountDialog(false);
    };

    const handleOnClickItem = (coinType) => {
        console.log("Show tx of coinType", coinType);
        let coin = getCoinInfo(coinType);
        // get the Value and Available;
        switch (coinType) {
            case "bto":
                coin.value = state.btoBalance ? state.btoBalance : 0;
                coin.available = state.btoAvailable ? state.btoAvailable : 0;
                break;
            case "usdt":
                coin.value = state.usdtBalance;
                coin.available = state.usdtAvailable;
                break;
            default:
                console.error(`Unknown coin type: ${coinType}`);
        }
        setTxCoin(coin);
        setTxAddress(state.walletAddress);  // TODO: as of 2022-07-06, we have only one address for both coins.
        setShouldShowTxRecordsDialog(true);
    }

    const handleSourceTokenAmountChange = useCallback(
        debounce(newValue => {
            if (triggeredBy !== "source") {
                return;
            }
            // const newValue = e.target.value.trim();
            newValue = newValue.trim();
            if (Number.isNaN(parseFloat(newValue))) {
                console.log("do nothing since it is not a valid float.")
                return;
            }
            setSwapSourceTokenAmount(newValue);
            setSwapDirection("from");

            if (SwapDestinationTokenType && parseFloat(newValue) > 0) {
                const ccy = SwapSourceTokenType.toUpperCase();
                const amount = toWei(newValue, ccy);
                GetSwapQuote({
                    fromCcy: SwapSourceTokenType.toUpperCase(),
                    toCcy: SwapDestinationTokenType.toUpperCase(),
                    amount,
                    way: "from",
                })
                    .then(data => {
                        console.log("quote", data);
                        // set destinatio amount.
                        setSwapDestinationTokenAmount(data.price);
                    })
            }
        }, 350),
        [triggeredBy, SwapSourceTokenType, SwapDestinationTokenType,]
    );

    useEffect(() => {
        handleSourceTokenAmountChange(SwapSourceTokenAmount);
    }, [SwapSourceTokenAmount, handleSourceTokenAmountChange]);

    const handleDestinationTokenAmountChange = useCallback(
        debounce(newValue => {
            if (triggeredBy !== "destination") {
                return;
            }

            // const newValue = e.target.value.trim();
            newValue = newValue.trim();
            if (Number.isNaN(parseFloat(newValue))) {
                console.log("do nothing since it is not a valid float.")
                return;
            }
            setSwapDestinationTokenAmount(newValue);
            setSwapDirection("to");

            if (SwapDestinationTokenType && parseFloat(newValue) > 0) {
                const ccy = SwapDestinationTokenType.toUpperCase();
                const amount = toWei(newValue, ccy);
                GetSwapQuote({
                    fromCcy: SwapSourceTokenType.toUpperCase(),
                    toCcy: SwapDestinationTokenType.toUpperCase(),
                    amount,
                    way: "to",
                })
                    .then(data => {
                        console.log("quote", data);
                        // set destinatio amount.
                        setSwapSourceTokenAmount(data.price);
                    })
            }
        }, 350),
        [triggeredBy, SwapSourceTokenType, SwapDestinationTokenType,]
    );

    useEffect(() => {
        handleDestinationTokenAmountChange(SwapDestinationTokenAmount);
    }, [SwapDestinationTokenAmount, handleDestinationTokenAmountChange]);

    return (
        <ThemeProvider theme={theme.wallet}>
            <WalletAppPage>
                <PageHeader auth={false} title={translations[language].wallet} showLogout={true} sx={{ width: "100%" }} />
                <MemberCard name={userInfo.lastName + ' ' + userInfo.firstName} address={state.walletAddress} accountType={AccountType}
                    onClickSwapBtn={handleSwapOpen}
                    onClickManageAccountBtn={(e) => { setShouldShowManageAccountDialog(true) }}
                />

                <Profolio items={items} xs={{ width: "100%" }} onClickItem={handleOnClickItem}></Profolio>
            </WalletAppPage>

            {
                getWalletDone &&
                <SwapDialog
                    open={ShowSwapDialog}
                    onClose={handleSwapClose}
                    // User
                    name={userInfo.lastName + ' ' + userInfo.firstName}
                    address={state.walletAddress}
                    // Source token
                    onClickSourceTokenPicker={() => { setSwapSourceTokenDialog(true) }}
                    sourceTokenType={SwapSourceTokenType}
                    sourceTokenAmount={SwapSourceTokenAmount}
                    sourceAsset={SwapSourceAsset}
                    onChangeSourceTokenAmount={(e) => {
                        setTriggeredBy("source");
                        setSwapSourceTokenAmount(e.target.value);
                    }}
                    // destination token
                    destinationTokenType={SwapDestinationTokenType}
                    destinationTokenAmount={SwapDestinationTokenAmount}
                    onChangeDestinationTokenAmount={(e) => {
                        setTriggeredBy("destination");
                        setSwapDestinationTokenAmount(e.target.value);
                    }}
                    onClickDestinationTokenPicker={() => { setSwapDestinationTokenDialog(true) }}
                    isDestinationTokenTypeSet={isDestinationTokenTypeSet}
                    // Tade button
                    // onTradeButtonClick={handleSwap}
                    shouldTradeBtnAvailable={shouldTradeBtnAvailable}
                >
                </SwapDialog>
            }

            <TokenPickerDialog
                open={SwapSourceTokenDialog}
                onClose={() => {
                    setSwapSourceTokenDialog(false)
                }}
                onSelectCoin={(coinType) => { setSwapSourceTokenType(coinType); setSwapSourceTokenDialog(false); }}
                assets={items}
                selectedCoinType={SwapSourceTokenType}
            >
            </TokenPickerDialog>

            <TokenPickerDialog
                open={SwapDestinationTokenDialog}
                onClose={() => { setSwapDestinationTokenDialog(false) }}
                onSelectCoin={(coinType) => { setSwapDestinationTokenType(coinType); setSwapDestinationTokenDialog(false); }}
                assets={items}
                selectedCoinType={SwapSourceTokenType}
            >
            </TokenPickerDialog>
            <ManageAccountDialog
                open={shouldShowManageAccountDialog}
                onClose={() => setShouldShowManageAccountDialog(false)}
                onClickAccountBtn={() => { setShouldShowManageAccountDialog(false); setShouldShowAccountDialog(true) }}
                onClickVerificationBtn={() => { setShouldShowManageAccountDialog(false); setShouldShowVerificationDialog(true) }}
                onClickSecurityBtn={() => { setShouldShowManageAccountDialog(false); setShouldShowSecurityDialog(true) }}
            >
            </ManageAccountDialog>

            <AccountDialog
                open={shouldShowAccountDialog}
                onClose={() => { setShouldShowAccountDialog(false) }}
                userInfo={userInfo}
                onSubmit={handleAccountFormSubmitDone}
            ></AccountDialog>
            <VerificationDialog
                open={shouldShowVerificationDialog}
                onClose={() => { setShouldShowVerificationDialog(false) }}
            ></VerificationDialog>
            <SecurityDialog
                open={shouldShowSecurityDialog}
                onClose={() => { setShouldShowSecurityDialog(false) }}
            ></SecurityDialog>

            <CoinTransactionHistoryDialog
                open={shouldShowTxRecordsDialog}
                onClose={() => setShouldShowTxRecordsDialog(false)}
                coin={txCoin}
                address={txAddress}
            ></CoinTransactionHistoryDialog>
        </ThemeProvider>
    )
}

export default Wallet;