import React, { useState } from 'react';
import Box from "@mui/material/Box";
import './Navbar.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link, useHistory } from "react-router-dom";
import md5 from 'md5';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Dialog from '@mui/material/Dialog';
import EmailIcon from '@mui/icons-material/Email';
import { InputLabel, OutlinedInput, Divider, FormControl, Grid, InputAdornment, Icon } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import theme from "../Themes";

import translations from '../i18n/translation.json';
import { useLanguage } from '../App'
import './CreateAccount.css'

function CreateAccount() {
  /**
   * TODO: validate inputs. if the input is bad, set the UI to invalid.
   */

  const history = useHistory();
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [RePassword, setRePassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [businessTitle, setBusinessTitle] = useState('');
  const [businessCompany, setBusinessCompany] = useState('');
  const [businessExpertise, setBusinessExpertise] = useState('');
  const [businessLink, setBusinessLink] = useState('');
  const [Referral, setReferral] = useState('');
  const [Validate, setValidate] = useState(false);
  const [ReponseBoolen, setReponseBoolen] = useState(false);
  const [ReponseString, setReponseString] = useState('');
  const [passwordState, setPasswordState] = useState('');
  const [isBusiness, setIsBusiness] = useState(false);
  const { language } = useLanguage();

  const SetStatesToInitial = () => {
    setEmail('');
    setPassword('');
    setRePassword('');
    setReferral('');
    setPasswordState('');
  }

  const VanishMessage = () => {
    setTimeout(() => {
      setReponseBoolen(false);
    }, 5000)
  }

  function containsNumber(str) {
    return /\d/.test(str);
  }
  function containsCapitalletters(str) {
    const Capitalletters = /[A-Z]/;
    return Capitalletters.test(str);
  }

  const handleBusinessChange = (e) => {
    setIsBusiness(e.target.checked);
  };

  const postData = (e) => {
    if (!Email || !Password || !firstName || !lastName || !mobileNumber) {
      setValidate(true);
      return;
    }
    if (Password.length < 8) {
      setReponseBoolen(true);
      setReponseString('Password must be greater than or equals to 8 charaters');
      VanishMessage();
      return;
    }
    if (containsCapitalletters(Password.trim()) === false) {
      setReponseBoolen(true);
      setReponseString('Password must contain atleast one capital letter');
      VanishMessage();
      return;
    }
    if (containsNumber(Password.trim()) === false) {
      setReponseBoolen(true);
      setReponseString('Password must contain atleast one number');
      VanishMessage();
      return;
    }
    if (Password.trim().toLowerCase() !== RePassword.trim().toLowerCase()) {
      setReponseBoolen(true);
      setReponseString('Password Not Matches');
      VanishMessage();
      return;
    }
    if (firstName.trim().length === 0) {
      setReponseBoolen(true);
      setReponseString('First name is required');
      VanishMessage();
      return;
    }

    if (lastName.trim().length === 0) {
      setReponseBoolen(true);
      setReponseString('Last name is required');
      VanishMessage();
      return;
    }

    if (mobileNumber.trim().length === 0) {
      setReponseBoolen(true);
      setReponseString('Mobile number is required');
      VanishMessage();
      return;
    }

    const options = {
      mode: 'cors',
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({
        "timestamp": new Date(),
        "password": md5(Password.trim().toLowerCase()),
        "email": Email,
        "firstName": firstName,
        "lastName": lastName,
        "mobileNumber": mobileNumber,
        "isBusiness": isBusiness,
        "businessTitle": businessTitle,
        "businessCompany": businessCompany,
        "businessExpertise": businessExpertise,
        "businessLink": businessLink,
        "referral": Referral.trim()
      })
    };

    // Making Request Here
    fetch(process.env.REACT_APP_BASE_URL + "/core/account/register", options)
      .then(response => response.json()).then(data => {
        if (data.error === "Email Already Exist") {
          setReponseBoolen(true);
          setReponseString('Email Already Exist');
        }
        else if (data.error === "Invalid Email") {
          setReponseBoolen(true);
          setReponseString('Invalid Email');
        }
        else if (data.code === 0) {
          setReponseBoolen(true);
          setReponseString('User registered successfully');
          SetStatesToInitial();
          handleMessageBoxOpen();
        }
        else if (data.code === 1) {
          setReponseBoolen(true);
          setReponseString('user not registered');
        }
        else {
          setReponseBoolen(true);
          setReponseString('user not registered');
        }
        // console.log(data);
      }).catch(err => {
        console.log(err);
      })

    VanishMessage();

  };

  const [MessageBox, setMessageBox] = React.useState(false);
  const handleMessageBoxOpen = () => {
    setMessageBox(true);
    setTimeout(() => {
      setMessageBox(false);
    }, 5000)
  };
  const handleMessageBoxClose = () => {
    setMessageBox(false);
    history.push('/');
  };

  let passwordEndAdornment;

  switch (passwordState) {
    case 'warning':
      passwordEndAdornment = <InputAdornment position="end">
        <Icon
          edge="end"
        >
          <img src="assets/warning-logo.svg" alt="warning logo" />
        </Icon>
      </InputAdornment>
      break
    case 'error':
      passwordEndAdornment = <InputAdornment position="end">
        <Icon
          edge="end"
        >
          <img src="assets/error-logo.svg" alt="error logo" />
        </Icon>
      </InputAdornment>
      break
    case 'valid':
      passwordEndAdornment = <InputAdornment position="end">
        <Icon
          edge="end"
        >
          <img src="assets/valid-logo.svg" alt="valid logo" />
        </Icon>
      </InputAdornment>
      break;
    default:
      passwordEndAdornment = null;
  }

  return (
    <ThemeProvider theme={theme.login}>
      <Box component={'div'} className="wallet-app-page"
        sx={{
          display: {
            md: 'flex',
            xs: 'flex'
          },
          alignItems: 'center',
          flexDirection: 'column',
          pb: "150px",
          maxWidth: '600px',
          margin: 'auto'
        }}
      >
        <Box component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            mb: "68px",
          }}
        >
          <Typography variant="h6" component="div"
            sx={{
              fontSize: '24px',
              fontWeight: '700',
              color: '#000',
            }}
          >
            {translations[language].signUp}
          </Typography>
        </Box>
        <Box component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: "70px"
          }}
        >
          <img style={{ width: '220px', }} src="assets/logo.svg" alt="logo" />
        </Box>
        <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', }} >
          <Box
            id="sign-in-form"
            component="form"
            method='POST'
            noValidate
            autoComplete="off"
            sx={{ width: '100%', color: '#FFFFFF', fontSize: '20px' }}
          >
            <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column' }} >
              <Box
                id="sign-up-form"
                component="form"
                method='POST'
                noValidate
                autoComplete="off"
                sx={{ width: '100%' }}
              >
                <FormControl>
                  <InputLabel shrink>{translations[language].email}</InputLabel>
                  <OutlinedInput
                    id="sign-up-email"
                    name="email"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].email}
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete='off' noValidate type="text"
                    autoFocus
                  />
                </FormControl>

                <FormControl>
                  <InputLabel shrink>{translations[language].password}</InputLabel>
                  <OutlinedInput
                    id="sign-up-password"
                    name="password"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].password}
                    value={Password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete='off' noValidate type="password"
                    endAdornment={passwordEndAdornment}
                  />
                </FormControl>

                <FormControl>
                  <InputLabel shrink>{translations[language].rePassword}</InputLabel>
                  <OutlinedInput
                    id="sign-up-re-password"
                    name="re-password"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].rePassword}
                    value={RePassword}
                    onChange={(e) => setRePassword(e.target.value)}
                    autoComplete='off' noValidate type="password"
                  />
                </FormControl>

                <FormControl>
                  <InputLabel shrink>{translations[language].firstName}</InputLabel>
                  <OutlinedInput
                    id="sign-up-firstName"
                    name="firstName"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].firstName}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    noValidate type="text"
                  />
                </FormControl>

                <FormControl>
                  <InputLabel shrink>{translations[language].lastName}</InputLabel>
                  <OutlinedInput
                    id="sign-up-lastName"
                    name="lastName"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].lastName}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    noValidate type="text"
                  />
                </FormControl>

                <FormControl>
                  <InputLabel shrink>{translations[language].mobileNumber}</InputLabel>
                  <OutlinedInput
                    id="sign-up-mobileNumber"
                    name="mobileNumber"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].mobileNumber}
                    value={mobileNumber}
                    onChange={(e) => {
                      // Allow only integers
                      if (e.target.value === "" || /^[0-9]+$/.test(e.target.value)) {
                        setMobileNumber(e.target.value);
                      }
                    }}
                    inputProps={{ 
                      pattern: "[0-9]*", 
                      inputMode: "numeric"  // Improves the mobile keyboard experience
                    }}
                    noValidate type="text"
                  />
                </FormControl>

                <FormControl>
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label={translations[language].isBusiness}
                    labelPlacement="start"
                    style={{ flexDirection: 'row', marginLeft: '0px' }}
                    checked={isBusiness}
                    onChange={handleBusinessChange}
                  />
                </FormControl>

                <FormControl style={{ display: isBusiness ? 'flex' : 'none' }}>
                  <InputLabel shrink>{translations[language].businessTitle}</InputLabel>
                  <OutlinedInput
                    id="sign-up-businessTitle"
                    name="businessTitle"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].businessTitle}
                    value={businessTitle}
                    onChange={(e) => setBusinessTitle(e.target.value)}
                    noValidate type="text"
                  />
                </FormControl>

                <FormControl style={{ display: isBusiness ? 'flex' : 'none' }}>
                  <InputLabel shrink>{translations[language].businessCompany}</InputLabel>
                  <OutlinedInput
                    id="sign-up-businessCompany"
                    name="businessCompany"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].businessCompany}
                    value={businessCompany}
                    onChange={(e) => setBusinessCompany(e.target.value)}
                    noValidate type="text"
                  />
                </FormControl>

                <FormControl style={{ display: isBusiness ? 'flex' : 'none' }}>
                  <InputLabel shrink>{translations[language].businessExpertise}</InputLabel>
                  <OutlinedInput
                    id="sign-up-businessExpertise"
                    name="businessExpertise"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].businessExpertise}
                    value={businessExpertise}
                    onChange={(e) => setBusinessExpertise(e.target.value)}
                    noValidate type="text"
                  />
                </FormControl>

                <FormControl style={{ display: isBusiness ? 'flex' : 'none' }}>
                  <InputLabel shrink>{translations[language].businessLink}</InputLabel>
                  <OutlinedInput
                    id="sign-up-businessLink"
                    name="businessLink"
                    onFocus={() => setValidate(false)}
                    placeholder={translations[language].enterYour + translations[language].businessLink}
                    value={businessLink}
                    onChange={(e) => setBusinessLink(e.target.value)}
                    noValidate type="text"
                  />
                </FormControl>

                <FormControl>
                  <InputLabel shrink>{translations[language].referral}</InputLabel>
                  <OutlinedInput
                    id="sign-up-referral"
                    name="referral"
                    onFocus={() => setValidate(false)}
                    placeholder='Referral/Optional'
                    value={Referral}
                    onChange={(e) => setReferral(e.target.value)}
                    autoComplete='off' noValidate type="password"
                  />
                </FormControl>

                {ReponseString === 'User registered successfully' ? <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Bold', letterSpacing: '1px', fontSize: '14px', color: 'green', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                  <CheckCircleOutlineIcon sx={{ mr: 1 }} />{ReponseString}
                </Typography> : <Typography variant="h6" sx={ReponseBoolen ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                  <ErrorOutlineIcon sx={{ mr: 1 }} />{ReponseString}
                </Typography>}
                <Typography variant="h6" sx={Validate ? { fontFamily: 'Gilroy-Light', letterSpacing: '1px', fontSize: '14px', color: 'red', textTransform: 'capitalize', mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' } : { display: 'none' }} component="div">
                  <ErrorOutlineIcon sx={{ mr: 1 }} />Fill all fields
                </Typography>

                <Button
                  id="submit"
                  onClick={postData}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.login.palette.primary.main, mt: "48px",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {translations[language].signUp}
                </Button>

                <Divider variant="middle" sx={{ mt: "50px" }} />
                <Grid container id="bottom-actions" sx={{ textAlign: "center", }}>
                  <Grid item xs={6}>
                    <Link className='link' to={'/'} >
                      {translations[language].login}
                    </Link>
                  </Grid>

                  <Grid item xs={6}>
                    <Link className='link' to={'/ForgotPassword'} >
                      {translations[language].forgotPassword}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* Message Box */}
            <Box>
              <Dialog
                open={MessageBox}
                onClose={handleMessageBoxClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', p: 4 }} >
                  <EmailIcon sx={{ fontSize: '50px', }} />
                  <Typography variant="h6" sx={{ fontFamily: 'Gilroy-Medium', letterSpacing: '1px', fontSize: '18px', lineHeight: '28px', textTransform: 'capitalize', textAlign: 'center', mt: 2 }} component="div">
                    {translations[language].goToEmail}
                  </Typography>
                </Box>
              </Dialog>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default CreateAccount;